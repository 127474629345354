<template>

    <div class="col-12 d-flex">

        <div class="form-group p-1">
            <label for="contents_amount" class="form-label font-weight-500">No. units in box</label>
            <input type="text" class="form-control" id="contents_amount" name="contents_amount" placeholder="" v-validate="'required'" v-model="box.contents_amount">
            <span class="text-danger">{{ errors.first('contents_amount') }}</span>
        </div>

        <div class="form-group p-1">
            <label for="length" class="form-label font-weight-500">Length*</label>
            <input type="text" class="form-control" id="length" name="length" placeholder="" v-validate="'required'" v-model="box.length">
            <span class="text-danger">{{ errors.first('length') }}</span>
        </div>

        <div class="form-group p-1">
            <label for="width" class="form-label font-weight-500">Width*</label>
            <input type="text" class="form-control" id="width" name="width" placeholder="" v-validate="'required'" v-model="box.width">
            <span class="text-danger">{{ errors.first('width') }}</span>
        </div>

        <div class="form-group p-1">
            <label for="height" class="form-label font-weight-500">Height*</label>
            <input type="text" class="form-control" id="height" name="height" placeholder="" v-validate="'required'" v-model="box.height">
            <span class="text-danger">{{ errors.first('height') }}</span>
        </div>

        <div class="form-group p-1">
            <label for="net_weight" class="form-label font-weight-500">Net Weight*</label>
            <input type="text" class="form-control" id="net_weight" name="net_weight" placeholder="" v-validate="'required'" v-model="box.net_weight">
            <span class="text-danger">{{ errors.first('height') }}</span>
        </div>

        <div class="form-group p-1">
            <label for="gross_weight" class="form-label font-weight-500">Gross Weight*</label>
            <input type="text" class="form-control" id="gross_weight" name="gross_weight" placeholder="" v-validate="'required'" v-model="box.gross_weight">
            <span class="text-danger">{{ errors.first('gross_weight') }}</span>
        </div>

		<div class="form-group p-1">
			<label for="description" class="form-label font-weight-500">Description</label>
			<input type="text" class="form-control" id="description" name="description" placeholder="" v-model="box.description">
			<span class="text-danger">{{ errors.first('description') }}</span>
		</div>

        <div class="form-group mr-2" style="margin-top: 24px; min-width: 80px;">
            <button class="btn btn-transparent text-primary" @click="$emit('add', box)" :disabled="!form_valid">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
                </svg>
                <span class="font-weight-500 mt-1 text-primary" style="line-height:28px; display: inline-block;">Add</span>
            </button>
        </div>

        <div class="form-group" style="margin-top: 31px;">
            <button class="btn btn-transparent text-grey pr-0" @click="$emit('hide')">Cancel</button>
        </div>

    </div>

</template>

<script>
export default {
    name: "BoxEditor",
    data : function(){
        return {
            box : {
                contents_amount : null,
                length : null,
                width : null,
                height : null,
                net_weight : null,
                gross_weight : null,
				description : null
            }
        }
    },

    computed : {

        form_valid(){
            return this.box.contents_amount > 0 &&
                    this.box.length > 0 &&
                    this.box.width > 0 &&
                    this.box.height > 0 &&
                    this.box.net_weight > 0 &&
                    this.box.gross_weight > 0;
        }

    },
}
</script>

<style scoped>

</style>
