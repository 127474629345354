<template>
    <div class="search-alerts__popup">
        <div class="search-alerts__popup-overlay"></div>
        <div class="search-alerts__popup-content">
            <div class="row">
                <div class="col">
                    <h3 class="search-alerts__popup-title">Add new News Alert</h3>
                </div>
                <div class="col">
                    <i class="fa fa-times float-right search-alerts__icon" @click="closeModal(alert)"></i>
                </div>

            </div>

            <div class="search-alerts__popup-form">
                <div class="form-group">
                    <div class="row">
                        <div class="col">
                            <label>Alert name</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <input type="text" class="form-control" v-model="alert.name">
                        </div>
                    </div>
                </div>
                <div class="search-alerts__popup-dynamic-form">
                    <p class="search-alerts__popup-dynamic-form__info">You will receive an email each day with the previous 24 hours results</p>
                    <div v-for="(keywordsGroup, keywordsGroupIndex) in alert.groups" v-bind:key="keywordsGroupIndex">
                        <div class="search-alerts__popup-dynamic-form__row">
                        <span class="search-alerts__popup-dynamic-form__cell">{{keywordsGroupIndex + 1}}</span>
                            <div class="search-alerts__popup-dynamic-form__keyword" v-for="(keywordChunk, keywordChunkIndex) in keywordsGroup.keywords" v-bind:key="keywordChunkIndex">
                                <label class="search-alerts__popup-dynamic-form__keyword-index">{{keywordChunkIndex + 1}}</label>
                                <input type="text" class="search-alerts__popup-dynamic-form__field" v-model="keywordChunk.value">
                            </div>
                            <span class="search-alerts__popup-dynamic-form__cell" v-if="alert.groups.length > 1">
                                <i class="fa fa-times-circle search-alerts__icon" @click="deleteKeywordGroup(keywordsGroup)"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <button class="btn--block" @click="addNewKeywordGroup()"><i class="fa fa-plus btn-icon"></i>Add New</button>
                <button class="btn-filled btn-filled-rounded btn-filled-right" @click="save()">Save</button>
                <button class="btn-filled--border btn-filled btn-filled-rounded btn-filled-right" @click="remove()">Delete</button>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

function createKeywordModel(value) {
    return {
        value,
    }
};

function createKeywordModels(amount) {

    const keywords = [];

    for (let i = 0; i < amount; i++) {
        keywords.push(createKeywordModel(''));
    }

    return keywords;
};

export default {
    data() {
        return {
            keywordsRowLimit: 4,
            blankAlert: {
                name: '',
                groups: [],
				scope : null
            }
        }
    },
    props: [
        'existingAlert',
		'scope'
    ],

    computed: {
        alert() {
            if (this.existingAlert) {
                return this.existingAlert;
            }

            return this.blankAlert;

        }
    },

	mounted() {

		if (!this.alert.groups.length) {
			this.addNewKeywordGroup();
		}

		this.blankAlert.scope = this.scope;
	},

    methods: {
        addNewKeywordGroup() {
            this.alert.groups.push({
                name: 'Group',
                keywords: createKeywordModels(this.keywordsRowLimit)
            });
        },

        deleteKeywordGroup(group) {
            this.alert.groups = this.alert.groups.filter(keywordGroup => keywordGroup !== group);
            axios.delete('/news-alerts/keywords-group/' + group.id, this.alert);
        },

        async save() {
            let response;

            if (!this.alert.id) {
                response = await axios.post('/news-alerts/alerts', this.alert);
            } else {
                response = await axios.put('/news-alerts/alerts/' + this.alert.id, this.alert);
            }

            this.blankAlert = response.data;

            this.closeModal(this.alert);
        },

        async remove() {
            if (this.alert.id) {
                await axios.delete('/news-alerts/alerts/' + this.alert.id);
            }

            this.closeModal(null);
        },

        closeModal(alert = null) {
            this.$emit('closeSearchAlertsModal', alert);
        }
    },


}
</script>

