<template>

    <!-- demo -->
    <div class="jtk-demo-canvas canvas-wide statemachine-demo jtk-surface" id="canvas">

        <text_box></text_box>

        <div class="w" id="phone1">
            ATTEMPT
            <div class="ep" action="phone1"></div>
        </div>
        <div class="w" id="phone2">PHONE INTERVIEW 2
            <div class="ep" action="phone2"></div>
        </div>
        <div class="w" id="inperson">
            SUCCESS
            <div class="ep" action="inperson"></div>
        </div>
        <div class="w" id="rejected">EFFECT
            <div class="ep" action="rejected"></div>
        </div>

    </div>
    <!-- /demo -->


</template>

<script>

import { jsPlumb } from 'jsplumb';
import text_box from './components/TextBox';
import panzoom from 'panzoom';

export default {

    name: "Board",

    data : function(){
        return {
            offsetX : -10000 + window.innerWidth / 2,
            offsetY : -10000
        }
    },

    components : {
        text_box
    },

    mounted () {

        // just grab a DOM element
        var element = document.querySelector('#canvas')

        // And pass it to panzoom
        var pz = panzoom(element, {
            bounds: true,
            boundsPadding: 0.1,
            beforeWheel: function(e) {
                return true;
            },

            filterKey: function(/* e, dx, dy, dz */) {
                // don't let panzoom handle this event:
                return true;
            },

            zoomDoubleClickSpeed: 1
        });

        pz.moveTo( this.offsetX, this.offsetY );

        // setup some defaults for jsPlumb.
        var js_plumb = jsPlumb.getInstance({
            Endpoint: ["Dot", {radius: 4}],
            Connector:"StateMachine",
            HoverPaintStyle: {stroke: "#ff0000", strokeWidth: 2 },
            ConnectionOverlays: [
                [ "Arrow", {
                    location: 1,
                    id: "arrow",
                    length: 15,
                    foldback: 1
                } ],
                [ "Label", { label: "FOO", id: "label", cssClass: "aLabel" }]
            ],
            Container: "canvas"
        });

        js_plumb.registerConnectionType("basic", { anchor:"Continuous", connector:"StateMachine" });

        window.jsp = js_plumb;

        var canvas = document.getElementById("canvas");
        var windows = jsPlumb.getSelector(".statemachine-demo .w");

        // bind a click listener to each connection; the connection is deleted. you could of course
        // just do this: js_plumb.bind("click", js_plumb.deleteConnection), but I wanted to make it clear what was
        // happening.
        js_plumb.bind("click", function (c) {
            js_plumb.deleteConnection(c);
        });

        // bind a connection listener. note that the parameter passed to this function contains more than
        // just the new connection - see the documentation for a full list of what is included in 'info'.
        // this listener sets the connection's internal
        // id as the label overlay's text.
        js_plumb.bind("connection", function (info) {
            info.connection.getOverlay("label").setLabel(info.connection.id);
        });

        // bind a double click listener to "canvas"; add new node when this occurs.
        jsPlumb.on(canvas, "dblclick", function(e) {
            newNode(e.offsetX, e.offsetY);
        });


        var newNode = function(x, y) {
            var d = document.createElement("div");
            var id = jsPlumbUtil.uuid();
            d.className = "w";
            d.id = id;
            d.innerHTML = id.substring(0, 7) + "<div class=\"ep\"></div>";
            d.style.left = x + "px";
            d.style.top = y + "px";
            js_plumb.getContainer().appendChild(d);
            initNode(d);
            return d;
        };


        //
        // initialise element as connection targets and source.
        //
        var initNode = function(el) {

            // initialise draggable elements.
            js_plumb.draggable(el);

            js_plumb.makeSource(el, {
                filter: ".ep",
                anchor: "Continuous",
                connectorStyle: { stroke: "#5c96bc", strokeWidth: 2, outlineStroke: "transparent", outlineWidth: 4 },
                connectionType:"basic",
                extract:{
                    "action":"the-action"
                },
                maxConnections: 30,
                onMaxConnections: function (info, e) {
                    alert("Maximum connections (" + info.maxConnections + ") reached");
                }
            });

            js_plumb.makeTarget(el, {
                dropOptions: { hoverClass: "dragHover" },
                anchor: "Continuous",
                allowLoopback: true
            });

            // this is not part of the core demo functionality; it is a means for the Toolkit edition's wrapped
            // version of this demo to find out about new nodes being added.
            //
            js_plumb.fire("jsPlumbDemoNodeAdded", el);
        };


        // suspend drawing and initialise.
        js_plumb.batch(function () {

            for (var i = 0; i < windows.length; i++) {
                initNode(windows[i], true);
            }

            // and finally, make a few connections
            js_plumb.connect({ source: "opened", target: "phone1", type:"basic" });

            // js_plumb.connect({ source: "phone1", target: "phone1", type:"basic" });

            js_plumb.connect(
                {
                    source: "phone1",
                    target: "inperson",
                    type:"basic"
                });

            js_plumb.connect({
                source:"phone2",
                target:"rejected",
                type:"basic"
            });
        });

        // jsPlumb.fire("jsPlumbDemoLoaded", js_plumb);
    }
}
</script>

<style scoped>


</style>
