\
<template>

	<div class="sq time" style="margin-bottom: 1px solid #DDD !important; justify-content: start !important">

		<div class="progress-time">

			<template v-for="activePurchaseRequest in activeRequests">
				<div class="bg-danger text-white month_block" :style="{ 'left' : `${getCurrentMonthStartAsPercent(activePurchaseRequest.due_at)}%`, 'width' : `${getMonthWidthInPercent()}%` }" v-if="getCurrentMonthStartAsPercent(activePurchaseRequest.due_at) >= 0 && getCurrentMonthStartAsPercent(activePurchaseRequest.due_at) < 100">
					&euro;{{ activePurchaseRequest.grandtotal }}
				</div>
			</template>

			<template v-for="approvedPurchaseRequest in approvedRequests">
				<div class="bg-warning text-white month_block middle_block" :style="{ 'left' : `${getCurrentMonthStartAsPercent(approvedPurchaseRequest.due_at)}%`, 'width' : `${getMonthWidthInPercent()}%` }" v-if="getCurrentMonthStartAsPercent(approvedPurchaseRequest.due_at) >= 0 && getCurrentMonthStartAsPercent(approvedPurchaseRequest.due_at) < 100">
					&euro;{{ approvedPurchaseRequest.grandtotal }}
				</div>
			</template>

			<template v-for="paidPurchaseRequest in paidRequests">
				<div class="bg-success text-white month_block bottom_block" :style="{ 'left' : `${getCurrentMonthStartAsPercent(paidPurchaseRequest.due_at)}%`, 'width' : `${getMonthWidthInPercent()}%` }" v-if="getCurrentMonthStartAsPercent(paidPurchaseRequest.due_at) >= 0 && getCurrentMonthStartAsPercent(paidPurchaseRequest.due_at) < 100">
					&euro;{{ paidPurchaseRequest.grandtotal }}
				</div>
			</template>

		</div>

		<div class="current_time" :style="{ 'margin-left' : `${getXAxisAsPercent(timestamp_now)}%`}" v-if="timestamp_now"></div>

		<div class="bg-lines">
			<span class="line" v-for="month in months"></span>
		</div>


	</div>

</template>


<script>

import {DateTime} from "luxon";

export default {
	name: "PurchaseTimeline",
	props: ['report', 'months'],

	data: function () {
		return {
			first_month_millis: null,
			last_month_millis: null,
			timestamp_now: null
		}
	},

	mounted() {

		this.first_month_millis = this.months[0].toMillis();
		this.last_month_millis = this.months[this.months.length - 1].toMillis();

		// Set current timestamp
		this.timestamp_now = DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss');

		// Enable tooltips for newly created DOM elements
		setTimeout(function () {
			$('[data-toggle="tooltip"]').tooltip();
		}, 500);
	},

	computed: {

		activeRequests : function () {

			const activeRequests = [];

			for(let request of this.report.active_purchase_requests){

				// same month exists in activeRequests
				let sameMonthExists = activeRequests.find(function (activeRequest) {
					return DateTime.fromSQL(activeRequest.due_at).toFormat('yyyy-MM') === DateTime.fromSQL(request.due_at).toFormat('yyyy-MM');
				});

				if(!sameMonthExists){
					activeRequests.push({
						due_at: request.due_at,
						grandtotal: request.grandtotal
					});
				}else{
					sameMonthExists.grandtotal += request.grandtotal;
				}


			}

			return activeRequests;

		},

		approvedRequests : function () {

			const approvedRequests = [];

			for (let request of this.report.approved_purchase_requests) {

				// same month exists in activeRequests
				let sameMonthExists = approvedRequests.find(function (approvedRequest) {
					return DateTime.fromSQL(approvedRequest.due_at).toFormat('yyyy-MM') === DateTime.fromSQL(request.due_at).toFormat('yyyy-MM');
				});

				if (!sameMonthExists) {
					approvedRequests.push({
						due_at: request.due_at,
						grandtotal: request.grandtotal
					});
				} else {
					sameMonthExists.grandtotal += request.grandtotal;
				}

			}

			return approvedRequests;

		},

		paidRequests : function () {

			const paidRequests = [];

			for (let request of this.report.paid_purchase_requests) {

				// same month exists in activeRequests
				let sameMonthExists = paidRequests.find(function (paidRequest) {
					return DateTime.fromSQL(paidRequest.due_at).toFormat('yyyy-MM') === DateTime.fromSQL(request.due_at).toFormat('yyyy-MM');
				});

				if (!sameMonthExists) {
					paidRequests.push({
						due_at: request.due_at,
						grandtotal: request.grandtotal
					});
				} else {
					sameMonthExists.grandtotal += request.grandtotal;
				}

			}

			return paidRequests;

		}

	},

	methods: {

		getMonthWidthInPercent() {
			return 100 / (this.months.length - 1);
		},

		getCurrentMonthStartAsPercent(point_timestamp) {

			return 100 / (this.months.length - 1) * (this.getCurrentMonthIndex(point_timestamp) - 1);

		},

		getCurrentMonthIndex(point_timestamp) {

			let point_timestamp_millis = DateTime.fromSQL(point_timestamp).toMillis();

			let start = 0;
			let point = point_timestamp_millis - this.first_month_millis;
			let end = this.last_month_millis - this.first_month_millis;

			// Point is before the first month
			if (point < start || point > end) {
				return false;
			}

			return Math.round(point / end * this.months.length);
		},

		getXAxisAsPercent(point_timestamp) {

			// Point
			let point_timestamp_millis = DateTime.fromSQL(point_timestamp).toMillis();

			//  Substract first month from timestamp_millis last_month_millis
			let start = 0;
			let point = point_timestamp_millis - this.first_month_millis;
			let end = this.last_month_millis - this.first_month_millis;

			// Point is before the first month
			if (point < start) {
				console.log('Point smaller than start');
				return 0;
			}

			// Point is after last month
			if (point > end) {
				console.log('Point larger than end');
				return 100;
			}

			return point / end * 100;

		},

		formatTimeStamp(timestamp) {
			return DateTime.fromSQL(timestamp).toFormat('d LLL yyyy');
		},

		/**
		 * Return true if item falls inside FROM - TO interval
		 * @param timestamp
		 * @returns {boolean}
		 */
		isInInterval(timestamp) {
			return (this.getXAxisAsPercent(timestamp) < 100 && this.getXAxisAsPercent(timestamp) > 0);
		}

	}
}
</script>

<style scoped>

</style>
