<template>
    <div ref="scheduler">
        <div class="dhx_cal_tab" name="grid1_tab" style="width:100px;"></div>
    </div>
</template>

<script>

    import {DateTime} from 'luxon';

    import "dhtmlx-scheduler";
    import "./dhtmlxscheduler_grid_view";

    const department_options = [
        {key: 'other', label: 'Other'},
        {key: 'in_vitro_and_ex_vivo', label: 'Science - Pre-clinical - In Vivo'},
        {key: 'in_vitro', label: 'Science - Pre-clinical - In Vitro'},
        {key: 'clinical', label: 'Science - Clinical'},
        {key: 'communication', label: 'Communication'},
        {key: 'operations', label: 'Operations'},
        {key: 'technology', label: 'Technology'}
    ];

    export default {

        name: "CalendarGrid",

        data: function () {
            return {
                events: []
            }
        },

        mounted: function () {

            var vm = this;

            scheduler.createGridView({
                name: "list",
                fields: [
                    {id: "text", label: 'Item', width: '*', valign: 'left', align: 'left'},
                    {
                        id: "details", label: 'Details', width: 300, align: 'left', template: function (start, end, ev) {

                            console.log(ev);

                            return department_options.find(item => item.key == ev.details).label;
                        }
                    },
                    {id: "start_date", label: 'From', align: 'left', width: 200},
                    {id: "end_date", label: 'To', align: 'left', width: 200}],
                rowHeight: 42
            });

            scheduler.locale.labels.list_tab = "List";
            scheduler.skin = "material";

            const full_header = [
                "day",
                "week",
                "month",
                "list",
                "date",
                "prev",
                "next"
            ];
            const compact_header = {
                rows: [
                    {
                        cols: [
                            "prev",
                            "date",
                            "next",
                        ]
                    },
                    {
                        cols: [
                            "day",
                            "week",
                            "month",
                            "list",
                        ]
                    }
                ]
            };

            // add a switch to select an appropriate config for a current screen size
            function resetConfig() {
                let header;
                if (window.innerWidth < 1000) {
                    header = compact_header;
                } else {
                    header = full_header;
                }
                scheduler.config.header = header;
                return true;
            }
            resetConfig();

            scheduler.attachEvent("onBeforeViewChange", resetConfig);
            scheduler.attachEvent("onSchedulerResize", resetConfig);
            scheduler.config.prevent_cache = true;
            scheduler.config.lightbox.sections = [
                {name: "description", height: 130, map_to: "text", type: "textarea", focus: true},
                {name: "Department", height: 43, type: "select", map_to: "details", options: department_options},
                {name: "time", height: 72, type: "time", map_to: "auto"}
            ];
            scheduler.config.limit_time_select = true;
            // Readonly for non-admin roles
            scheduler.config.readonly = !is_admin;
            // 0 refers to Sunday, 6 - to Saturday
            scheduler.ignore_month = function (date) {
                if (date.getDay() == 6 || date.getDay() == 0) //hides Saturdays and Sundays
                    return true;
            };
            // 0 refers to Sunday, 6 - to Saturday
            scheduler.ignore_week = function (date) {
                if (date.getDay() == 6 || date.getDay() == 0) //hides Saturdays and Sundays
                    return true;
            };

            scheduler.config.time_step = 15;
            scheduler.config.scroll_hour = 7;
            scheduler.attachEvent("onTemplatesReady", function () {
                scheduler.templates.event_bar_date = function (start, end, ev) {
                    return "";
                };
                scheduler.templates.month_scale_date = function (date) {
                    return DateTime.fromJSDate(date).toFormat('EEE');
                };
                scheduler.templates.week_scale_date = function (date) {
                    return DateTime.fromJSDate(date).toFormat('EEE');
                };
            });

            scheduler.config.resize_month_events = false;
            scheduler.config.resize_week_events = false;
            scheduler.config.resize_month_timed = false;

            // Show multi day events as regular events in day and week view
            scheduler.config.all_timed = true;

            scheduler.init(this.$refs.scheduler, new Date(), "month");

            scheduler.setLoadMode("month");

            // When saving event
            scheduler.attachEvent("onEventSave", function (id, ev, is_new) {

                let start_date_time_string = DateTime.fromJSDate(ev.start_date).toFormat('yyyy-MM-dd HH:mm:ss');
                let end_date_time_string = DateTime.fromJSDate(ev.end_date).toFormat('yyyy-MM-dd HH:mm:ss');

                var colors = {
                    other : "#ebecf0",
                    in_vitro_and_ex_vivo : "#8dd1ff",
                    in_vitro : "#fdf0ba",
                    clinical : "#fdbabd",
                    communication : "#bbf2d3",
                    operations : "#beb8ef",
                    technology : "#b4bac4"
                };


                ev.color = colors[parseInt(ev.details)]; // Account for 1 index
                ev.textColor = '#000000';
                scheduler.updateEvent(id);

                if (is_new) {

                    axios.post('/event', {
                        start_date: start_date_time_string,
                        end_date: end_date_time_string,
                        text: ev.text,
                        details: ev.details,
                        color: ev.color,
                        textColor: ev.textColor
                    }).then(r => {
                        let evt = scheduler.getEvent(id);
                        evt.identifier = r.data.identifier;
                        scheduler.updateEvent(id);

                    });

                    return true;

                } else {

                    axios.put('/event/' + scheduler.getEvent(id).identifier, {
                        start_date: start_date_time_string,
                        end_date: end_date_time_string,
                        text: ev.text,
                        details: ev.details,
                        color: ev.color
                    }).then(r => {
                        scheduler.getEvent(id).identifier = r.data.identifier;
                        scheduler.updateEvent(id);
                    });

                    return true;

                }

            });

            // Delete event
            scheduler.attachEvent("onEventDeleted", (id, ev, abc) => {

                // If has identifier, delete from database
                if (ev.identifier)
                    axios.delete('/event/' + ev.identifier).then(r => {
                        return true;
                    });

            });

            // Change view
            scheduler.attachEvent("onViewChange", function (old_mode, old_date, mode, date) {
                vm.loadEvents();
                return true;
            });


            // Change view
            scheduler.attachEvent("onClick", function (id, e) {

                if (scheduler.config.readonly) {

                    let event = scheduler.getEvent(id);
                    let start_time = DateTime.fromJSDate(event.start_date).toLocaleString(DateTime.DATETIME_MED);
                    let end_time = DateTime.fromJSDate(event.end_date).toLocaleString(DateTime.DATETIME_MED);

                    Swal.fire({
                        html: `Start: ${start_time} <br>End: ${end_time}`,
                        title: event.text,
                        text: `Start: ${start_time} <br>End: ${end_time}`,
                        // icon: 'info',
                        confirmButtonText: "Close",
                        showClass: {
                            popup: ''
                        },
                        hideClass: {
                            popup: ''
                        }
                    });

                }

                return true;
            });

            this.loadEvents();

        },

        methods: {

            loadEvents() {

                // Clear existing events
                scheduler.clearAll();
                this.events = [];

                let from;
                let to;

                if(scheduler.getState().mode == "list"){
                    from = '1980-01-01 00:00:00';
                    to = '2180-01-01 00:00:00';
                }else{
                    from = DateTime.fromJSDate(scheduler._get_timeunit_start(), {zone: "utc"}).toFormat('yyyy-MM-dd HH:mm:ss');
                    to = DateTime.fromJSDate(scheduler._get_view_end(), {zone: "utc"}).toFormat('yyyy-MM-dd HH:mm:ss');
                }

                axios.get('/event', {
                    params: {
                        from: from,
                        to: to
                    }
                }).then(response => {
                    this.events = response.data;

                    this.events.forEach(event => {

                        // Adds event
                        scheduler.addEvent({
                            start_date: event.start_date,
                            end_date:   event.end_date,
                            text:   event.text,
                            color: event.color,
                            textColor: event.textColor,
                            details: event.details,
                            identifier: event.identifier // userdata
                        });

                    })

                    // scheduler.parse(this.events);
                    scheduler.updateView();
                });

            }

        }

    }

</script>

<style scoped>
    @import "~dhtmlx-scheduler/codebase/dhtmlxscheduler_material.css";
</style>
