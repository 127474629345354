<template>
	<div class="search-alerts storage">
		<div class="head">
			<div class="container">
				<div class="row">
					<div class="col-sm-8">
						<div class="row" v-if="alertsMenu && alertsMenu.length">
							<div class="col-sm no-gutter">
								<h3 v-text="title"></h3>
							</div>
							<div class="col-sm no-gutter">
								<div class="search-wrapper">
									<div class="input-wrapper">
										<input type="text" id="storage-search" name="search" placeholder="Search" value="" class="in-search">
									</div>
								</div>
							</div>
						</div>
						<h5 v-if="!alertsMenu || !alertsMenu.length">No Alerts...</h5>
					</div>
					<div class="col-sm-4 float-end">
						<button class="btn-filled btn-filled-rounded btn-filled-right" @click="toggleAddNewAlertForm(null, true)">
							<i class="fa fa-plus-circle btn-icon"></i> Add new {{scope}} alert
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="container" v-if="alertsMenu.length">
			<div class="documents-wrapper grid">
				<div class="document-nav">
					<ul class="breadcrumbs">
						<li class="breadcrumbs__item"><a class="breadcrumbs__item__link">{{breadcrumbs_root}}</a></li>
						<li class="breadcrumbs__item">
							<a class="breadcrumbs__item__link" v-if="alert">{{ alert.name }}</a></li>
					</ul>
					<div class="tools">
						<ul class="nav nav-tabs">
							<li v-for="alertItem in alertsMenu" v-bind:key="alertItem.id">
								<a v-bind:class="alert && alert.id === alertItem.id ? 'active' :''" @click="getAlert(alertItem.id)">{{ alertItem.name }}</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="content">
					<div class="row">
						<div class="col-sm">
							<h4>Alerts List</h4>
						</div>
						<div class="col-sm">
							<button v-if="alert && alert.id" class="btn-filled btn-filled-rounded btn-filled-right btn-filled--danger" @click="deleteAlert(alert.id)">
								<i class="fa fa-trash btn-icon"></i>Remove Alert
							</button>
						</div>
					</div>
					<div class="search-alerts__groups" v-if="alert && alert.groups">
						<div class="row">
							<div class="col-6" v-for="(keywordGroup, key) in alert.groups" v-bind:key="keywordGroup.id">
								<div class="search-alerts__group" @click="toggleAddNewAlertForm(alert)">
									<div class="search-alerts__group__head">
										<span class="search-alerts__group__order">{{ key + 1 }}</span>
										<h5 class="search-alerts__group__title">{{ keywordGroup.name }}
											{{ key + 1 }}</h5>
									</div>
									<br/>
									<div class="search-alerts__group__inner-list">
										<ul class="search-alerts__group__inner-list-items">
											<li class="search-alerts__group__inner-list-item search-alerts__group__inner-list-item--descriptor">
												Keywords:
											</li>
											<li class="search-alerts__group__inner-list-item" v-for="keyword in keywordGroup.keywords" v-bind:key="keyword.id">
                                                <span>
                                                    {{ keyword.value }}
                                                </span>
											</li>
										</ul>
									</div>
									<a href="#" class="search-alerts__group__edit-btn"><i class="fas fa-pencil-alt btn-icon"></i>
										Edit</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<new-alert
			v-bind:existingAlert="alert"
			v-if="showAddNewAlertForm"
			:scope="scope"
			v-on:closeSearchAlertsModal="toggleAddNewAlertForm($event)">

		</new-alert>
	</div>
</template>
<script>

import NewAlert from './NewAlert';
import Subscribers from './Subscribers';

export default {
	name: "SearchAlertsHome",
	components: {
		NewAlert,
		Subscribers,
	},
	props: [
		'title',
		'scope',
		'breadcrumbs_root'
	],
	data() {
		return {
			alertsMenu: [],
			alert: null,
			showAddNewAlertForm: false,
			showSubscribers: false,
		};
	},

	async mounted() {
		this.synchronizeAlerts();

		this.$on('closeSearchAlertsModal', (payload) => {
			console.log('payload', payload);
		})
	},

	methods: {

		async getAlert(id) {
			const alertResponse = await axios.get('/news-alerts/alerts/' + id);
			this.alert = alertResponse.data;
		},

		async getAlerts() {

			var vm = this;

			const alertsReponse = await axios.get('/news-alerts/alerts', {
				params : {
					scope : vm.scope
				}
			});
			this.alertsMenu = alertsReponse.data
		},

		async deleteAlert(id) {
			await axios.delete('/news-alerts/alerts/' + id);
			this.synchronizeAlerts();
		},

		async deleteAll() {
			await axios.delete('/news-alerts/alerts');
			this.alertsMenu = [];
			this.alert = null;
		},

		async toggleAddNewAlertForm(alert, newAlert = false) {
			this.alert = alert;
			this.showAddNewAlertForm = !this.showAddNewAlertForm;
			await this.getAlerts();

			if (!newAlert && !alert && this.alertsMenu.length) {
				await this.getAlert(this.alertsMenu[0].id);
			}
		},

		async toggleSubscribersPopup(subscribers) {
			this.showSubscribers = !this.showSubscribers;

			if (subscribers) {
				this.alert = {
					...this.alert,
					subscribers: subscribers,
				}

				await axios.put('/news-alerts/alerts/' + this.alert.id, this.alert);
			}

		},

		async synchronizeAlerts() {
			await this.getAlerts();

			if (this.alertsMenu.length) {
				this.getAlert(this.alertsMenu[0].id);
			}
		}
	}

};
</script>
