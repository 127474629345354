<template>
    <div class="chat-main-box">

        <div id="notifications" v-if="subscribed_to_event !== null">

            <div class="p-1 cursor-pointer" v-if="subscribed_to_event" @click="setEventSubscription(false)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#38c172" class="bi bi-bell-fill" viewBox="0 0 16 16">
                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"/>
                </svg>

                <span class="text-secondary small">Notifications On</span>
            </div>

            <div class="p-1 cursor-pointer" @click="setEventSubscription(true)" v-else>

                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#d0211c" class="bi bi-bell-slash-fill" viewBox="0 0 16 16">
                    <path d="M5.164 14H15c-1.5-1-2-5.902-2-7 0-.264-.02-.523-.06-.776L5.164 14zm6.288-10.617A4.988 4.988 0 0 0 8.995 2.1a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 7c0 .898-.335 4.342-1.278 6.113l9.73-9.73zM10 15a2 2 0 1 1-4 0h4zm-9.375.625a.53.53 0 0 0 .75.75l14.75-14.75a.53.53 0 0 0-.75-.75L.625 15.625z"/>
                </svg>

                <span class="text-secondary small">Notifications Off</span>

            </div>



        </div>

        <ul class="chat-list pl-2 pr-2 pb-5">

            <template v-for="(message, mKey) in messages" v-if="messages.length">

                <li class="separator text-center" v-if="showDaySeparator(mKey)">
                    <span class="btn btn-rounded btn-sm btn-secondary btn-outline-secondary disabled no-shadow" v-text="smartDate(message.created_at)"></span>
                    <hr>
                </li>

                <li v-bind:class="{ reverse : (message.user_identifier === user.identifier), sameuser : (mKey > 0 && message.user_identifier == messages[(mKey - 1)].user_identifier) }" v-if="messages.length > 0">
                    <!-- -->
                    <div class="chat-img">
                        <img :src="'/plugins/profilethumbnailgenerator/profile_thumbnail.php?name=' + message.user_name" alt="user"/>
                    </div>
                    <div class="chat-content">
                        <div class="usr-date">
                            <h5 class="chat-user" v-text="message.user_name"></h5>
                            <div class="chat-time" v-text="formatTime(message.created_at)"></div>
                        </div>
                        <div class="box">
                            <p class="text" v-html="processContent(message.content)"></p>
                        </div>
                    </div>
                </li>

                <div v-else="">
                    <div class="mt-5"></div>
                    <svg class="circular" viewBox="25 25 50 50">
                        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
                    </svg>
                    <div class="mb-5"></div>
                </div>

            </template>

        </ul>

        <div class="col-lg-12 d-inline-flex send-chat-box pb-2 chat-input" v-bind:class="{ typing: content.length > 0 }" v-if="commenting_enabled">
            <input type="text" v-on:keyup="keyUp($event)" placeholder="Type your message here" class="form-control b-0 chat-input" v-model="content"></input>
            <button v-on:click="send($event)" v-if="content.length > 0" type="button" class="btn btn-info no-shadow btn-circle btn-lg">
                <img src="/assets/icons/send-chat.svg" alt="" style="width: 22px; height: 22px; margin-top: 5px;">
            </button>
        </div>

        <div class="col-lg-12 text-center" v-else="">
            <h5 style="color:#999">Only participants to this experiment are allowed to write comments</h5>
        </div>

    </div>
</template>
<script>

    import moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
    import anchorme from 'anchorme';

    export default {

        props: [
            'channel_type',
            'channel',
            'commenting_enabled',
            'scroll_bottom'
        ],

        data: function () {
            return {
                messages: [],
                content: '',
                user: user,
                full_channel_name: null,
                channel_identifier : null,
                now_utc: null,
                uicontrol: {
                    send_active: true,
                    sending: false
                },
                subscribed_to_event : null,
            }
        },

        mounted: function () {

            this.now_utc = moment().utc();
            this.full_channel_name = ((this.channel_type == 'private') ? 'private-' : '') + this.channel;
            this.get();
            this.listen();
            this.getSubscriptionStatus();

        },

        methods: {

            setEventSubscription(status){

                var vm = this;

                if(status){

                    axios.post('/event_subscription', {
                        owner_model : 'App\\Models\\User',
                        model_id :  user.id,
                        identifier : vm.full_channel_name
                    }).then(r => {
                        if(r.data.success)
                            vm.subscribed_to_event = true;
                    })

                }else{

                    axios.delete('/event_subscription/' + vm.full_channel_name).then(r => {
                        if(r.data.success)
                            vm.subscribed_to_event = false;
                    })

                }

            },

            getSubscriptionStatus(){

                axios.get('/event_subscription/private-' + this.channel + '/exists').then(response => {
                   this.subscribed_to_event = response.data.exists;
                });

            },

            listen: function () {

                var vm = this;

                window.Echo.private(this.channel)
                    .listenForWhisper('message', (message) => {

						// Emit for parent to display blink
						vm.$emit('received_message');

                        vm.messages.push(message);
                        vm.scrollBottom();
                    });



				window.Echo.join('chat.' + this.channel).here((users) => {
					console.log(users);
				})
				.joining((user) => {
					console.log(user.name);
				})
				.leaving((user) => {
					console.log(user.name);
				})
				.error((error) => {
					console.error(error);
				});

            },

            scrollBottom: function () {
                if(this.scroll_bottom)
					$(".chat-list").animate({scrollTop: document.body.scrollHeight}, 50);
            },

            send: function (event = null) {

                if (event)
                    event.preventDefault();

                var vm = this;

                if (!vm.uicontrol.send_active)
                    return;

                vm.uicontrol.send_active = false;

                function sanitizeString(str) {
                    str = str.replace(/[^a-z0-9áéíóúñüăâîșț \: \/ \.\",_!?#$%^&*)(-]/gim, "");
                    return str.trim();
                }

                var sanitized_input_content = sanitizeString(vm.content);
                vm.content = '';

                if (sanitized_input_content.length < 1) return false;

                let now_utc = moment().utc().format("YYYY-MM-DD HH:mm:ss");

                let message = {
                    content: sanitized_input_content,
                    user_identifier: vm.user.identifier,
                    created_at: now_utc,
                    user_name: vm.user.name
                }

                // Push locally
                vm.messages.push(message);

                // Whisper to connected clients
                window.Echo.private(this.channel)
                    .whisper('message', {
                        content: sanitized_input_content,
                        user_identifier: vm.user.identifier,
                        created_at: now_utc,
                        user_name: vm.user.name
                    });

                vm.scrollBottom();

                axios.post('/message/write', {
                    channel_uid: vm.full_channel_name,
                    message_content: sanitized_input_content
                }).then(

                    response => {
                        vm.uicontrol.send_active = true;
                    },

                    error => {
                        console.log(error);
                    });

            },

            processContent: function (content) {

                var processed = anchorme(content, {
                    attributes: [ // attributes is an array
                        // an object that describes the attribute
                        // will produce: class="link"
                        {
                            name: "class",
                            value: "chat-link"
                        },
                        {
                            name: "target",
                            value: "_blank"
                        }
                    ]
                });

                return processed;
            },

            get: function (before_message) {

                var vm = this;

                let params = {
                    "channel_uid": vm.full_channel_name,
                    "limit": 15,
                };

                // Before Message
                if (before_message !== undefined)
                    params.before_message = before_message;

                axios.get('/message/get', {
                    params: params
                }).then(response => {

                        // Concatenate response array before existing messages
                        vm.messages = response.data.concat(vm.messages);

                        if (before_message === undefined) {

                            vm.scrollBottom();

                            setTimeout(function () {
                                vm.scrollBottom();
                            }, 500);

                        }
                    },
                    error => {
                        console.log(error);
                    });

            },

            loadMore: function () {
                this.get(this.messages[0]);
            },

            keyUp: function (e) {
                e.preventDefault();
                if (e.keyCode == 13) this.send();
            },

            formatTime: function (message_created_at) {
                let message_moment_utc = moment(message_created_at.replace(' ', 'T').concat('Z')).utc();
                let local = message_moment_utc.local()
                let formatted = local.format("HH:mm");
                return formatted;
            },

            smartDate: function (message_created_at) {

                var message_time = moment(message_created_at);
                var now = moment.utc();

                switch (now.dayOfYear() - message_time.dayOfYear()) {

                    case 0:
                        return 'Today';
                        break;

                    case 1:
                        return 'Yesterday';
                        break;

                    default:
                        var message_moment_utc = moment(message_created_at.replace(' ', 'T').concat('Z')).utc();
                        return message_moment_utc.local().format("DD MMMM YYYY");
                        break;
                }


            },

            showDaySeparator: function (mKey) {

                var vm = this;

                var current_message = vm.messages[mKey];
                var previous_message = vm.messages[mKey - 1];

                if (typeof previous_message === 'undefined')
                    return true;

                return moment(current_message.created_at).dayOfYear() !== moment(previous_message.created_at).dayOfYear();

            }


        }

    }

</script>
