<template>
    <div class="search-me">

        <input type="search" id="exp-search" name="search" placeholder="Search" class="in-search" v-model="string">

        <svg class="circular small" viewBox="25 25 50 50" v-if="uicontrol.loading">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="4" stroke-miterlimit="10" />
        </svg>

        <div class="message-center search_results" v-if="search_results.length">
            <component
                :key="search_result.url"
                :is="search_result.type"
                :action="action"
                :search_result="search_result"
                @selected_search_result="selected"
                v-for="search_result in search_results">
            </component>
        </div>
    </div>
</template>

<script>

    import manufacturing_study_result from './results/ExperimentResult';
    import manufacturing_study from './results/Experiment';
    import experiment_result from './results/ExperimentResult';
    import experiment from './results/Experiment';
	import idea from './results/Idea';
    import empty from './results/Empty.vue';

    export default {

        name: 'Search',

        components: {
            empty,
			idea,
            experiment_result,
            experiment,
            manufacturing_study,
            manufacturing_study_result
        },

        props: [
            'category', 'action'
        ],

        data: function () {

            return {
                pickrs: {},
                string: '',
                search_results: [],
                uicontrol: {
                    loading: false
                },
                empty_result: [
                    {
                        type: 'empty',
                        title: 'No results'
                    }
                ],
            }

        },

        mounted() {

        },

        watch : {

            string : function(thestring, theoldstring){

                this.uicontrol.loading = (thestring.length > 0) ? true : false;

                if(thestring.length >= 3){
                    this.search();
                }else{
                    this.search_results = [];
                }

            }

        },

        methods: {

            selected(params){
                this.$emit('selected_search_result', params);
                this.search_results = [];
                this.string = '';
            },

            search: _.debounce(function () {

                    var vm = this;

                    // Preventspinner
                    vm.$root.active_http_requests--;

                    vm.uicontrol.loading = true;

                    axios.post('/search/' + vm.category, {
                        'string': vm.string,
                    }).then(response => {

                        console.log(response.data);

                        // Populate list of results
                        vm.search_results = (response.data.hits.length) ? response.data.hits : vm.empty_result;

                        // Hide spinner
                        vm.uicontrol.loading = false;
                        return true;

                    }).finally(() => {

                        vm.uicontrol.loading = false;

                    })

                },

                500 // debounce time

            )

        }

    }


</script>
