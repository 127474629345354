<template>
    <main>

        <button class="btn" @click="ask()" v-if="can_ask">Enable Push Notifications</button>

    </main>
</template>

<script>

    import firebase from "firebase/app";
    import 'firebase/analytics';
    import 'firebase/messaging';

    let messaging_supported = firebase.messaging.isSupported();


    if(messaging_supported){

        // Your web app's Firebase configuration
        // For Firebase JS SDK v7.20.0 and later, measurementId is optional
        var firebaseConfig = {
            apiKey: "AIzaSyBI3lAf2RVxqw73ix1rDNEGhmzXHqLkFhI",
            authDomain: "raphaellabs.firebaseapp.com",
            projectId: "raphaellabs",
            storageBucket: "raphaellabs.appspot.com",
            messagingSenderId: "1068148702664",
            appId: "1:1068148702664:web:4f0d81c809173c50a9d4fd",
            measurementId: "G-XWVJZSV41T"
        };

        // Initialize Firebase
        firebase.initializeApp(firebaseConfig);

        var messaging = firebase.messaging();


        window.messaging = messaging;

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/firebase-messaging-sw.js').then(function (registration) {
                // console.log("Service Worker Registered");
                // messaging.useServiceWorker(registration);
            });
        }

        // // Handle incoming messages. Called when:
        // // - a message is received while the app has focus
        // // - the user clicks on an app notification created by a service worker
        // //   `messaging.onBackgroundMessage` handler.
        // messaging.onMessage((payload) => {
        //     console.log('Message received. ', payload);
        //     // ...
        // });

        firebase.analytics();

    }



    export default {

        name: "PushNotifications",

        data: function () {
            return {
                can_ask : null,
                token : null
            }
        },

        mounted() {

            if(!messaging_supported){
                this.can_ask = false;
                return;
            }

            switch ( Notification.permission ) {

                case 'default':

                    // Do nothing
                    this.can_ask = true;

                    break;


                case 'granted':

                    this.can_ask = false;

                    // Do we have a token in localstorage?
                    let stored_token = localStorage.getItem('fcm_token');

                    this.getToken().then(token => {

                        this.token = token;

                        // If we have a stored token, and it's not the same as this token
                        if(stored_token !== null && stored_token !== this.token){
                            this.deleteToken(stored_token); // Delete the old one from the db
                            this.storeToken(token); // Store the new one
                            localStorage.setItem('fcm_token', token); //
                        }else if(stored_token == null){
                            localStorage.setItem('fcm_token', token); //
                        }

                    });



                    break;


                case 'denied':

                    this.can_ask = false;
                    break;

            }

        },

        methods: {

            // Show Swal before asking permission from the browser
            ask: function () {

                var vm = this;

                Swal.fire({
                    title: "Enable Push Notifications?",
                    text: "Push notifications allow you to receive updates even when your app / website is not open",
                    icon: 'question',
                    confirmButtonText: "Yes, enable!",
                    cancelButtonText: "Not now",
                    showCancelButton: true,
                }).then((result) => {

                    if (result.value)
                        vm.requestPermission();

                });

            },

            // Request permission from the browser
            requestPermission() {

                var vm = this;

                Notification.requestPermission().then(permission => {

                    if (permission == 'granted') {

                        vm.getToken().then(token => {

                            vm.token = token;
                            vm.can_ask = false;

                            localStorage.setItem('fcm_token', token);

                            vm.storeToken(token);

                        });

                    }

                })

            },

            getToken() {

                // Get registration token. Initially this makes a network call, once retrieved
                // subsequent calls to getToken will return from cache.
                return messaging.getToken({vapidKey: 'BDZLzk08ARsYIYSVXFJYvjE7tLwWJ5qF7LfgRQqrSOSnpEoMgDmeeS51ZNG2I_gYKmaIMIbYHfn68pW7Mi1qWsM'}).catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                });

            },

            storeToken: function (token) {
                axios.post('/fcm-device/store', {
                    'fcm_token': token,            // Firebase Clould Messaging token for this device
                    'user_agent': navigator.userAgent   // User agent
                });
            },

            deleteToken(stored_token){
                axios.post('/fcm-device/delete', {
                    'fcm_token': stored_token,            // Firebase Clould Messaging token for this device
                });
            }

        }

    }
</script>

<style scoped>

</style>
