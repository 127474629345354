<template>
    <div>
        <template v-if="Boolean(shipment)">

            <div class="container">
                <div class="row">
                    <div class="col-4 pt-2 pb-2 pl-0">
                        <span>Origin: <br>
                    <h5>
                        {{ shipment.origin.address.addressLocality }}
                    </h5>
                </span>
                    </div>
                    <div class="col-4 pt-2 pb-2">
                        <span>Destination: <br>
                            <h5>
                                {{ shipment.destination.address.addressLocality }}
                            </h5>
                        </span>
                    </div>
                    <div class="col-4 pt-2 pb-2 pr-0 text-right">
                        <span>Status: <br>
                            <h5>
                                {{ shipment.status.description }}
                            </h5>
                        </span>
                    </div>
                </div>
            </div>

            <h5 class="mt-4">Events:</h5>

            <div class="container mb-5">
                <div class="row" v-for="event in shipment.events" style="border-bottom: 1px dotted #ddd;">
                    <div class="col-4 pt-2 pb-2 pl-0">
                        Location: {{ event.location.address.addressLocality }}
                    </div>
                    <div class="col-4 pt-2 pb-2">
                        Timestamp: {{ event.timestamp }}
                    </div>
                    <div class="col-4 pt-2 pb-2 pr-0 text-right">Status: {{ event.description }}</div>
                </div>
            </div>

        </template>


        <div class="error">
            <h4 class="text-danger"></h4>
        </div>

    </div>
</template>

<script>
export default {
    name: "TrackingInformation",
    props: [
        'tracking_number'
    ],

    data: function () {
        return {
            shipment: null
        }
    },

    mounted() {
        if(tracking_number)
            this.get();
    },

    methods: {

        get() {

			let vm = this;

			return axios.get('/dashboard/shipment/tracking/dhl/' + vm.tracking_number).then(response => {
				vm.shipment = response.data.shipments[0]
			}).catch(err => {
				console.log('error', err);
			})

        },

        showError(title, description) {

        }

    }
}
</script>

<style scoped>

</style>
