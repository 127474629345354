<template>
    <div id="user_permissions" :style="{'margin-left' : margin_left }">
        <div class="usrs-groups">
            <div class="user" v-for="(user, pKey) in users_local" :class="{ toslide : pKey > 0 }" :key="pKey" :style="{ 'right': `${pKey * 20}px`, 'z-index': 9-`${pKey}`}">
                <img style="width:37px; margin-right: 10px" :src="'/plugins/profilethumbnailgenerator/profile_thumbnail.php?name=' + user.name" :alt="user.name" :title="user.name">
                <i class="remove" @click="removeUser(user, pKey)" v-if="editable" title="Remove user from participants">+</i>
            </div>
        </div>
        <div class="form-group add-rm-users">

            <input class="form-control" type="text" v-if="uicontrol.show_search" v-model="searchstr">

            <span class="close-input" v-if="uicontrol.show_search" @click="() => {
                    uicontrol.show_search = !uicontrol.show_search;
                    results = [];
                    searchstr = '';
                }">
            </span>

            <div class="d-block">
                <ul v-if="results" class="results badge">
                    <li v-for="result in results" @click="addUser(result)" :class="{ exists : userExists(result) , disabled : Boolean(result.disabled) }">
                        {{result.name}}
                    </li>
                </ul>
            </div>

            <button v-if="!uicontrol.show_search && editable" class="plus" @click="() => {
                    $emit('open_search')
                    uicontrol.show_search = !uicontrol.show_search;
                    results = [];
                    searchstr = '';
                }">
                {{ uicontrol.show_search ? '-' : '+' }}
            </button>

        </div>
    </div>

</template>

<script>
    export default {
        name: "UserPermissions",

        props: ['users', 'editable', 'permission_name', 'permission_prerequisite', 'permission_forbidden'],

        data: function () {

            return {
                users_local: [],
                searchstr: '',
                results: [],
                empty_result: [
                    {
                        name: 'No results',
                        disabled : true
                    }
                ],
                uicontrol: {
                    focused: false,
                    loading: false,
                    show_search: false
                }
            }

        },

        mounted() {
            this.users_local = this.users;
        },

        watch: {

            searchstr: function (thestring, theoldstring) {

                this.uicontrol.loading = (thestring.length > 0) ? true : false;

                if (thestring.length == 0)
                    this.results = [];

                this.search();
            }

        },

        computed : {

            margin_left(){
                return ((this.users.length * 55 <= 55) ? 55 : this.users.length * 55) + 'px';
            }

        },

        methods: {

            userExists(user) {

                let exists = this.users_local.find(item => item.identifier === user.identifier);

                console.log('Exists:', Boolean(exists));

                return exists;
            },

            search: _.debounce(function () {

                    var vm = this;

                    if (vm.searchstr.length < 2)
                        return;

                    vm.uicontrol.loading = true;

                    // Show spinner

                    // If empty search string, clear results
                    if (vm.searchstr == '') {
                        vm.results = [];
                        return false;
                    }

                    //
                    axios.post('/search/users', {
                        'string': vm.searchstr,
                        'permission_prerequisite' : vm.permission_prerequisite,
                        'permission_forbidden' : vm.permission_forbidden
                    }).then(response => {

                        // Populate list of results
                        vm.results = (response.data.length) ? response.data : vm.empty_result;

                        // Hide spinner
                        vm.uicontrol.loading = false;

                        return true;

                    }, error => {

                        // Hide spinner
                        vm.uicontrol.loading = false;
                        return false;

                    });

                },

                500 // debounce time

            ),

            addUser(user) {

                var vm = this;

                if(Boolean(user.disabled))
                    return;

                if (this.userExists(user))
                    return;

                this.users_local.push(user);
                this.searchstr = '';
                this.uicontrol.show_search = false;
                this.results = [];

                axios.post('/permission', {
                    user_identifier: user.identifier,
                    permission: `${this.permission_name}`
                });

                // Emit
                vm.$emit('added_user', user);

                if(this.permission_name.includes('experiment.participate'))
                    this.sendNewCollaboratorNotification(user);


            },

            removeUser(user, key) {

                this.users_local.splice(key, 1);

                axios.delete('/permission', {
                    data: {
                        user_identifier: user.identifier,
                        permission: `${this.permission_name}`
                    }
                });

            },


            sendNewCollaboratorNotification(participant){

                var vm = this;

                axios.post('/notification', {
                    user_identifier : participant.identifier,
                    notification_class : 'App\\Notifications\\Science\\Experiment\\NewCollaborator',
                    notification_params : {
                        experiment_id : parseInt(vm.permission_name.replace('experiment.participate.', ''))
                    }
                });

            }

        }
    }
</script>
