<template>
	<canvas :id="id"></canvas>
</template>

<script>

import { Chart } from 'chart.js';

export default {
	name: "CovidStatisticsChart",
	data(){
		return {
			id : 'to-be-created',
			chart : null
		}
	},
	props : ['datasets', 'labels'],
	created(){
		this.id = 'chart_' + Math.floor((Math.random() * 1000) + 1)
	},
	mounted(){
		this.initChart();
	},
	methods: {
		initChart(){

			var vm = this;

			const ctx = document.getElementById(this.id).getContext('2d');

			let chart_object = new Chart(ctx, {
				type: 'line',
				data: {
					labels: vm.labels,
					datasets: vm.datasets
				},
				options: {
					scales: {
						y: {
							beginAtZero: true
						}
					}
				}
			});
		},
	}
}

</script>

<style scoped>

</style>