<template>
	<div class="card p-4 mb-5" :id="'chart_parent' + id">
		<v-select multiple :options="options" v-model="selected_countries"></v-select>

		<chart :datasets="datasets" :labels="labels" v-if="uicontrol.ready && !uicontrol.loading"></chart>

		<div class="position-relative chart-placeholder" v-if="uicontrol.loading || !uicontrol.ready" :style="{
			'width' : '100%',
			'height' : uicontrol.chart_placeholder_height + 'px'
		}">
			<div class="fullwidth text-center position-absolute" style="top: 50%">
				<div class="spinner-border" role="status">
					<span class="sr-only">Loading...</span>
				</div>
			</div>

		</div>

		<p class="fullwidth text-center small text-grey pl-4 mt-3"> X axis = consecutive days. Last item is the current day. </p>
		<p class="fullwidth small text-grey mt-3">Score is calculated with the following formula: </p>
		<p class="fullwidth small text-grey">(Vaccination Policy Score) + (Positive Rate Score) + (Stringency Score) - (Facial Covering) + (Workplace Attendance Change From Baseline) + (Grocery and Pharmacy Change From Baseline)</p>

	</div>

</template>

<script>

import chart from './CovidStatisticsChart';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

const retry = require('retry');

const operation = retry.operation({
	retries: 10,
	factor: 3,
	minTimeout: 1 * 1000,
	maxTimeout: 5 * 1000,
	randomize: true,
});

export default {
	name: "ScoresCountries",
	data: function () {
		return {
			id : 0,
			selected_countries: [
				{
					code: 'CAN',
					label: 'Canada'
				},
				{
					code: 'DEU',
					label: 'Germany'
				},
				{
					code: 'IND',
					label: 'India'
				},
				{
					code: 'ITA',
					label: 'Italy'
				},
				{
					code: 'GBR',
					label: 'United Kingdom'
				},
				{
					code: 'USA',
					label: 'United States'
				}
			],
			options: [
				{
					code: 'AFG',
					label: 'Afghanistan'
				},
				{
					code: 'ALB',
					label: 'Albania'
				},
				{
					code: 'DZA',
					label: 'Algeria'
				},
				{
					code: 'ASM',
					label: 'American Samoa'
				},
				{
					code: 'AND',
					label: 'Andorra'
				},
				{
					code: 'AGO',
					label: 'Angola'
				},
				{
					code: 'AIA',
					label: 'Anguilla'
				},
				{
					code: 'ATA',
					label: 'Antarctica'
				},
				{
					code: 'ATG',
					label: 'Antigua and Barbuda'
				},
				{
					code: 'ARG',
					label: 'Argentina'
				},
				{
					code: 'ARM',
					label: 'Armenia'
				},
				{
					code: 'ABW',
					label: 'Aruba'
				},
				{
					code: 'AUS',
					label: 'Australia'
				},
				{
					code: 'AUT',
					label: 'Austria'
				},
				{
					code: 'AZE',
					label: 'Azerbaijan'
				},
				{
					code: 'BHS',
					label: 'Bahamas'
				},
				{
					code: 'BHR',
					label: 'Bahrain'
				},
				{
					code: 'BGD',
					label: 'Bangladesh'
				},
				{
					code: 'BRB',
					label: 'Barbados'
				},
				{
					code: 'BLR',
					label: 'Belarus'
				},
				{
					code: 'BEL',
					label: 'Belgium'
				},
				{
					code: 'BLZ',
					label: 'Belize'
				},
				{
					code: 'BEN',
					label: 'Benin'
				},
				{
					code: 'BMU',
					label: 'Bermuda'
				},
				{
					code: 'BTN',
					label: 'Bhutan'
				},
				{
					code: 'BOL',
					label: 'Bolivia'
				},
				{
					code: 'BIH',
					label: 'Bosnia and Herzegovina'
				},
				{
					code: 'BWA',
					label: 'Botswana'
				},
				{
					code: 'BRA',
					label: 'Brazil'
				},
				{
					code: 'IOT',
					label: 'British Indian Ocean Territory'
				},
				{
					code: 'VGB',
					label: 'British Virgin Islands'
				},
				{
					code: 'BRN',
					label: 'Brunei'
				},
				{
					code: 'BGR',
					label: 'Bulgaria'
				},
				{
					code: 'BFA',
					label: 'Burkina Faso'
				},
				{
					code: 'BDI',
					label: 'Burundi'
				},
				{
					code: 'KHM',
					label: 'Cambodia'
				},
				{
					code: 'CMR',
					label: 'Cameroon'
				},
				{
					code: 'CAN',
					label: 'Canada'
				},
				{
					code: 'CPV',
					label: 'Cape Verde'
				},
				{
					code: 'CYM',
					label: 'Cayman Islands'
				},
				{
					code: 'CAF',
					label: 'Central African Republic'
				},
				{
					code: 'TCD',
					label: 'Chad'
				},
				{
					code: 'CHL',
					label: 'Chile'
				},
				{
					code: 'CHN',
					label: 'China'
				},
				{
					code: 'CXR',
					label: 'Christmas Island'
				},
				{
					code: 'CCK',
					label: 'Cocos Islands'
				},
				{
					code: 'COL',
					label: 'Colombia'
				},
				{
					code: 'COM',
					label: 'Comoros'
				},
				{
					code: 'COK',
					label: 'Cook Islands'
				},
				{
					code: 'CRI',
					label: 'Costa Rica'
				},
				{
					code: 'HRV',
					label: 'Croatia'
				},
				{
					code: 'CUB',
					label: 'Cuba'
				},
				{
					code: 'CUW',
					label: 'Curacao'
				},
				{
					code: 'CYP',
					label: 'Cyprus'
				},
				{
					code: 'CZE',
					label: 'Czech Republic'
				},
				{
					code: 'COD',
					label: 'Democratic Republic of the Congo'
				},
				{
					code: 'DNK',
					label: 'Denmark'
				},
				{
					code: 'DJI',
					label: 'Djibouti'
				},
				{
					code: 'DMA',
					label: 'Dominica'
				},
				{
					code: 'DOM',
					label: 'Dominican Republic'
				},
				{
					code: 'TLS',
					label: 'East Timor'
				},
				{
					code: 'ECU',
					label: 'Ecuador'
				},
				{
					code: 'EGY',
					label: 'Egypt'
				},
				{
					code: 'SLV',
					label: 'El Salvador'
				},
				{
					code: 'GNQ',
					label: 'Equatorial Guinea'
				},
				{
					code: 'ERI',
					label: 'Eritrea'
				},
				{
					code: 'EST',
					label: 'Estonia'
				},
				{
					code: 'ETH',
					label: 'Ethiopia'
				},
				{
					code: 'FLK',
					label: 'Falkland Islands'
				},
				{
					code: 'FRO',
					label: 'Faroe Islands'
				},
				{
					code: 'FJI',
					label: 'Fiji'
				},
				{
					code: 'FIN',
					label: 'Finland'
				},
				{
					code: 'FRA',
					label: 'France'
				},
				{
					code: 'PYF',
					label: 'French Polynesia'
				},
				{
					code: 'GAB',
					label: 'Gabon'
				},
				{
					code: 'GMB',
					label: 'Gambia'
				},
				{
					code: 'GEO',
					label: 'Georgia'
				},
				{
					code: 'DEU',
					label: 'Germany'
				},
				{
					code: 'GHA',
					label: 'Ghana'
				},
				{
					code: 'GIB',
					label: 'Gibraltar'
				},
				{
					code: 'GRC',
					label: 'Greece'
				},
				{
					code: 'GRL',
					label: 'Greenland'
				},
				{
					code: 'GRD',
					label: 'Grenada'
				},
				{
					code: 'GUM',
					label: 'Guam'
				},
				{
					code: 'GTM',
					label: 'Guatemala'
				},
				{
					code: 'GGY',
					label: 'Guernsey'
				},
				{
					code: 'GIN',
					label: 'Guinea'
				},
				{
					code: 'GNB',
					label: 'Guinea-Bissau'
				},
				{
					code: 'GUY',
					label: 'Guyana'
				},
				{
					code: 'HTI',
					label: 'Haiti'
				},
				{
					code: 'HND',
					label: 'Honduras'
				},
				{
					code: 'HKG',
					label: 'Hong Kong'
				},
				{
					code: 'HUN',
					label: 'Hungary'
				},
				{
					code: 'ISL',
					label: 'Iceland'
				},
				{
					code: 'IND',
					label: 'India'
				},
				{
					code: 'IDN',
					label: 'Indonesia'
				},
				{
					code: 'IRN',
					label: 'Iran'
				},
				{
					code: 'IRQ',
					label: 'Iraq'
				},
				{
					code: 'IRL',
					label: 'Ireland'
				},
				{
					code: 'IMN',
					label: 'Isle of Man'
				},
				{
					code: 'ISR',
					label: 'Israel'
				},
				{
					code: 'ITA',
					label: 'Italy'
				},
				{
					code: 'CIV',
					label: 'Ivory Coast'
				},
				{
					code: 'JAM',
					label: 'Jamaica'
				},
				{
					code: 'JPN',
					label: 'Japan'
				},
				{
					code: 'JEY',
					label: 'Jersey'
				},
				{
					code: 'JOR',
					label: 'Jordan'
				},
				{
					code: 'KAZ',
					label: 'Kazakhstan'
				},
				{
					code: 'KEN',
					label: 'Kenya'
				},
				{
					code: 'KIR',
					label: 'Kiribati'
				},
				{
					code: 'XKX',
					label: 'Kosovo'
				},
				{
					code: 'KWT',
					label: 'Kuwait'
				},
				{
					code: 'KGZ',
					label: 'Kyrgyzstan'
				},
				{
					code: 'LAO',
					label: 'Laos'
				},
				{
					code: 'LVA',
					label: 'Latvia'
				},
				{
					code: 'LBN',
					label: 'Lebanon'
				},
				{
					code: 'LSO',
					label: 'Lesotho'
				},
				{
					code: 'LBR',
					label: 'Liberia'
				},
				{
					code: 'LBY',
					label: 'Libya'
				},
				{
					code: 'LIE',
					label: 'Liechtenstein'
				},
				{
					code: 'LTU',
					label: 'Lithuania'
				},
				{
					code: 'LUX',
					label: 'Luxembourg'
				},
				{
					code: 'MAC',
					label: 'Macau'
				},
				{
					code: 'MKD',
					label: 'Macedonia'
				},
				{
					code: 'MDG',
					label: 'Madagascar'
				},
				{
					code: 'MWI',
					label: 'Malawi'
				},
				{
					code: 'MYS',
					label: 'Malaysia'
				},
				{
					code: 'MDV',
					label: 'Maldives'
				},
				{
					code: 'MLI',
					label: 'Mali'
				},
				{
					code: 'MLT',
					label: 'Malta'
				},
				{
					code: 'MHL',
					label: 'Marshall Islands'
				},
				{
					code: 'MRT',
					label: 'Mauritania'
				},
				{
					code: 'MUS',
					label: 'Mauritius'
				},
				{
					code: 'MYT',
					label: 'Mayotte'
				},
				{
					code: 'MEX',
					label: 'Mexico'
				},
				{
					code: 'FSM',
					label: 'Micronesia'
				},
				{
					code: 'MDA',
					label: 'Moldova'
				},
				{
					code: 'MCO',
					label: 'Monaco'
				},
				{
					code: 'MNG',
					label: 'Mongolia'
				},
				{
					code: 'MNE',
					label: 'Montenegro'
				},
				{
					code: 'MSR',
					label: 'Montserrat'
				},
				{
					code: 'MAR',
					label: 'Morocco'
				},
				{
					code: 'MOZ',
					label: 'Mozambique'
				},
				{
					code: 'MMR',
					label: 'Myanmar'
				},
				{
					code: 'NAM',
					label: 'Namibia'
				},
				{
					code: 'NRU',
					label: 'Nauru'
				},
				{
					code: 'NPL',
					label: 'Nepal'
				},
				{
					code: 'NLD',
					label: 'Netherlands'
				},
				{
					code: 'ANT',
					label: 'Netherlands Antilles'
				},
				{
					code: 'NCL',
					label: 'New Caledonia'
				},
				{
					code: 'NZL',
					label: 'New Zealand'
				},
				{
					code: 'NIC',
					label: 'Nicaragua'
				},
				{
					code: 'NER',
					label: 'Niger'
				},
				{
					code: 'NGA',
					label: 'Nigeria'
				},
				{
					code: 'NIU',
					label: 'Niue'
				},
				{
					code: 'PRK',
					label: 'North Korea'
				},
				{
					code: 'MNP',
					label: 'Northern Mariana Islands'
				},
				{
					code: 'NOR',
					label: 'Norway'
				},
				{
					code: 'OMN',
					label: 'Oman'
				},
				{
					code: 'PAK',
					label: 'Pakistan'
				},
				{
					code: 'PLW',
					label: 'Palau'
				},
				{
					code: 'PSE',
					label: 'Palestine'
				},
				{
					code: 'PAN',
					label: 'Panama'
				},
				{
					code: 'PNG',
					label: 'Papua New Guinea'
				},
				{
					code: 'PRY',
					label: 'Paraguay'
				},
				{
					code: 'PER',
					label: 'Peru'
				},
				{
					code: 'PHL',
					label: 'Philippines'
				},
				{
					code: 'PCN',
					label: 'Pitcairn'
				},
				{
					code: 'POL',
					label: 'Poland'
				},
				{
					code: 'PRT',
					label: 'Portugal'
				},
				{
					code: 'PRI',
					label: 'Puerto Rico'
				},
				{
					code: 'QAT',
					label: 'Qatar'
				},
				{
					code: 'COG',
					label: 'Republic of the Congo'
				},
				{
					code: 'REU',
					label: 'Reunion'
				},
				{
					code: 'ROU',
					label: 'Romania'
				},
				{
					code: 'RUS',
					label: 'Russia'
				},
				{
					code: 'RWA',
					label: 'Rwanda'
				},
				{
					code: 'BLM',
					label: 'Saint Barthelemy'
				},
				{
					code: 'SHN',
					label: 'Saint Helena'
				},
				{
					code: 'KNA',
					label: 'Saint Kitts and Nevis'
				},
				{
					code: 'LCA',
					label: 'Saint Lucia'
				},
				{
					code: 'MAF',
					label: 'Saint Martin'
				},
				{
					code: 'SPM',
					label: 'Saint Pierre and Miquelon'
				},
				{
					code: 'VCT',
					label: 'Saint Vincent and the Grenadines'
				},
				{
					code: 'WSM',
					label: 'Samoa'
				},
				{
					code: 'SMR',
					label: 'San Marino'
				},
				{
					code: 'STP',
					label: 'Sao Tome and Principe'
				},
				{
					code: 'SAU',
					label: 'Saudi Arabia'
				},
				{
					code: 'SEN',
					label: 'Senegal'
				},
				{
					code: 'SRB',
					label: 'Serbia'
				},
				{
					code: 'SYC',
					label: 'Seychelles'
				},
				{
					code: 'SLE',
					label: 'Sierra Leone'
				},
				{
					code: 'SGP',
					label: 'Singapore'
				},
				{
					code: 'SXM',
					label: 'Sint Maarten'
				},
				{
					code: 'SVK',
					label: 'Slovakia'
				},
				{
					code: 'SVN',
					label: 'Slovenia'
				},
				{
					code: 'SLB',
					label: 'Solomon Islands'
				},
				{
					code: 'SOM',
					label: 'Somalia'
				},
				{
					code: 'ZAF',
					label: 'South Africa'
				},
				{
					code: 'KOR',
					label: 'South Korea'
				},
				{
					code: 'SSD',
					label: 'South Sudan'
				},
				{
					code: 'ESP',
					label: 'Spain'
				},
				{
					code: 'LKA',
					label: 'Sri Lanka'
				},
				{
					code: 'SDN',
					label: 'Sudan'
				},
				{
					code: 'SUR',
					label: 'Suriname'
				},
				{
					code: 'SJM',
					label: 'Svalbard and Jan Mayen'
				},
				{
					code: 'SWZ',
					label: 'Swaziland'
				},
				{
					code: 'SWE',
					label: 'Sweden'
				},
				{
					code: 'CHE',
					label: 'Switzerland'
				},
				{
					code: 'SYR',
					label: 'Syria'
				},
				{
					code: 'TWN',
					label: 'Taiwan'
				},
				{
					code: 'TJK',
					label: 'Tajikistan'
				},
				{
					code: 'TZA',
					label: 'Tanzania'
				},
				{
					code: 'THA',
					label: 'Thailand'
				},
				{
					code: 'TGO',
					label: 'Togo'
				},
				{
					code: 'TKL',
					label: 'Tokelau'
				},
				{
					code: 'TON',
					label: 'Tonga'
				},
				{
					code: 'TTO',
					label: 'Trinidad and Tobago'
				},
				{
					code: 'TUN',
					label: 'Tunisia'
				},
				{
					code: 'TUR',
					label: 'Turkey'
				},
				{
					code: 'TKM',
					label: 'Turkmenistan'
				},
				{
					code: 'TCA',
					label: 'Turks and Caicos Islands'
				},
				{
					code: 'TUV',
					label: 'Tuvalu'
				},
				{
					code: 'VIR',
					label: 'U.S. Virgin Islands'
				},
				{
					code: 'UGA',
					label: 'Uganda'
				},
				{
					code: 'UKR',
					label: 'Ukraine'
				},
				{
					code: 'ARE',
					label: 'United Arab Emirates'
				},
				{
					code: 'GBR',
					label: 'United Kingdom'
				},
				{
					code: 'USA',
					label: 'United States'
				},
				{
					code: 'URY',
					label: 'Uruguay'
				},
				{
					code: 'UZB',
					label: 'Uzbekistan'
				},
				{
					code: 'VUT',
					label: 'Vanuatu'
				},
				{
					code: 'VAT',
					label: 'Vatican'
				},
				{
					code: 'VEN',
					label: 'Venezuela'
				},
				{
					code: 'VNM',
					label: 'Vietnam'
				},
				{
					code: 'WLF',
					label: 'Wallis and Futuna'
				},
				{
					code: 'ESH',
					label: 'Western Sahara'
				},
				{
					code: 'YEM',
					label: 'Yemen'
				},
				{
					code: 'ZMB',
					label: 'Zambia'
				},
				{
					code: 'ZWE',
					label: 'Zimbabwe'
				}
			],
			colors: [
				'#DD4124',
				'#88B04B',
				'#34568B',
				'#EFC050',
				'#FF6F61',
				'#6B5B95',
				'#45B8AC',
				'#BC243C',
				'#B565A7',
				'#DFCFBE',
				'#92A8D1',
				'#955251',
				'#D65076',
				'#5B5EA6',
				'#E15D44',
				'#C3447A',
			],
			datasets: [],
			uicontrol: {
				loading: false,
				ready: false,
				chart_placeholder_height: null
			},
			step_size: 7,
		}
	},

	components: {
		'v-select': vSelect,
		chart
	},


	props: [
		'steps_back'
	],

	created(){
		this.id = Math.floor((Math.random() * 1000) + 1)
	},

	computed: {

		selected_country_codes() {
			let codes = this.selected_countries.map(country => {
				return country.code;
			})

			return codes.toString();
		},

		labels() {

			let labels_array = [];

			if (this.datasets.length == 0)
				return [];

			for (let i = 0; i < this.datasets[0].data.length; i++) {
				labels_array.push(i);
			}

			return labels_array;


		}

	},

	watch: {

		selected_countries() {
			this.getData();
		}

	},

	mounted() {

		var vm = this;

		let card_content_width = document.getElementById('chart_parent').clientWidth - 100;
		this.uicontrol.chart_placeholder_height = card_content_width / 2;

		this.getData();

	},


	methods: {

		getData() {

			var vm = this;

			vm.uicontrol.loading = true;

				axios.get('https://raphstats.xyz/api/data/country-scores', {
					params: {
						countries: vm.selected_country_codes,
						steps_back: vm.steps_back,
						step_size: vm.step_size
					}
				}).then(r => {

					if (r.status == 404)
						throw('Error, please retry');

					vm.datasets = [];

					let increment = 0;

					for (let key in r.data) {

						let color = vm.colors[increment];

						vm.datasets.push({
							label: r.data[key].label,
							borderWidth: 1,
							data: r.data[key].data.reverse(),
							backgroundColor: 'transparent',
							borderColor: color,
						})

						increment++;

					}

					vm.uicontrol.ready = true;
					vm.uicontrol.loading = false;

				});

		},

	}

}
</script>

<style scoped></style>