<template>
    <div>
        <div class="container">
            <div class="experiment-top-nav">
                <div style="z-index: 1; position: relative">

                    <h4 class="pagetitle">
                        <template v-if="params.edit_mode">
                            <input id="board-title" type="text" placeholder="Title" v-model="board.title">
                            <input id="board-description" maxlength="70" type="text" placeholder="Description (max 70 characters)" v-model="board.description">
                        </template>
                        <template v-else>
                            <span v-text="board.title"></span> <span v-text="board.description"></span>
                        </template>
                    </h4>

					<a href="#" @click="uicontrol.timestamp_authenticity_popup.visible = !uicontrol.timestamp_authenticity_popup.visible" v-if="params.blockchain_mode">

						<span v-text="uicontrol.timestamp_authenticity_popup.visible ? 'Hide' : 'Show'"></span>

						authenticity verification instructions</a>

					<div id="timestamp_authenticity_overlay" v-if="uicontrol.timestamp_authenticity_popup.visible">

						<h5>Unique version identifiers</h5>

						<span>Base64 Encoded data hash: <code v-text="params.block_base64_data_hash"></code></span><br>
						<span>Board version identifier: <code v-text="params.block_identifier"></code></span><br> <br>

						<h5>Amazon Web Services Quantum Ledger Database:</h5>
						<p>At the time when this board version was created, an <a href="https://www.google.com/search?q=immutable+definition" target="_blank">immutable</a> record was created on <a target="_blank" href="https://aws.amazon.com/qldb/">Amazon's QLDB Service</a>.</p>
							<p>The record contains three key pieces of data:</p>
							<ol>
								<li>This resource's identifier</li>
								<li>This resource's base 64 encoded data hash </li>
								<li>A timestamp of the creation of the record on AWS QLDB</li>
							</ol>

						<h5>How to verify</h5>

						<p>An authorized representative of Raphael Labs can navigate to the QLDB service in the AWS Control Panel and run the following query in the <strong>PartiQL Editor:</strong></p>
						<code>
							select * from _ql_committed_board_hash_history as history <br>
							where history.data.BoardBlockIdentifier = '{{params.block_identifier}}'
						</code> <br><br>

						<p>The returned result will have a set of datapoints which prove the authenticity of this content's creation timestamp: </p>

						<code>BoardBlockBase64DataHash (string)</code> - is an exact match with the <code>base64encoded</code> dataset of this version of the board.
						<br>
						<code>Metadata -> txTime (timestamp)</code> - shows the complete timestamp at the creation of the record in AWS's Quantum Ledger Database

						<br><br>

						<p>The two fields above will be used to prove that the current form of this board version has been unchanged since the aforementioned timestamp</p><br><br>

						<h5>Want to decode the Base 64 Encoded data to see that it matches the board?</h5>

						<p>You can use your favorite locally installed programming language to decode the encoded data, or you can use an online compiler such as this <a href="https://www.programiz.com/python-programming/online-compiler/">Online Python Compiler</a></p>

						<span><strong>Copy this code in the compiler:</strong></span> <br>

<code>
<pre>
<span class="text-danger" style="font-family: 'Courier New', 'Arial', sans-serif; font-size: 14px; font-weight: bold">
	import base64
	base64_string = "ENTER_BASE64DATA_HERE"

	base64_bytes = base64_string.encode('ascii')
	message_bytes = base64.b64decode(base64_bytes)
	message = message_bytes.decode('ascii')

	print(message)
</span>
</pre>
</code>

						<p>Then copy the complete base 64 encoded data from here: <a target="_blank" :href="window.location.href.replace('block', 'block-base-64-data')">Base 64 Encoded Data</a> and paste it between the double quotes on line 2 of the code. Please make sure there are no spaces between the double quotes and the data</p>
						<p>You can then run the code and you will end up with a JSON representation of this exact board.</p>

						<h5>Want to double check the hash against the Base 64 Encoded content?</h5>

						<p>In an empty window in the online Python compiler, copy this code: </p>

						<code>
<pre>
<span class="text-danger" style="font-family: 'Courier New', 'Arial', sans-serif; font-size: 14px; font-weight: bold">
	import hashlib

	hash_string = "ENTER_BASE64DATA_HERE"
	sha_signature = hashlib.sha256(hash_string.encode()).hexdigest()

	print(sha_signature)
</span>
</pre>
						</code>

						<p>Then copy the complete base 64 encoded data from here: <a target="_blank" :href="window.location.href.replace('block', 'block-base-64-data')">Base 64 Encoded Data</a> and paste it between the double quotes on line 2 of the code. Please make sure there are no spaces between the double quotes and the data</p>
						<p>You can then run the code and you will end up with a hash of the board version's base 64 encrypted data. The resulting hash will be the same as the hash presented above: <code v-text="params.block_base64_data_hash"></code></p>
						<p>This confirms that the content of this board's version has been the same since the date of creation, mentioned in the <code>Metadata -> txTime (timestamp)</code> inside the Amazon QLDB Ledger Database Record</p>

					</div>

                </div>

                <template v-if="user">
                    <div id="user_permissions" style="z-index: 1;">
                        <user_permissions :users="participants" :permission_name="'idea.edit.' + board.id" :permission_prerequisite="'ideas.view'" :editable="params.edit_mode"></user_permissions>
                    </div>
                </template>

                <template v-if="params.blockchain_mode && !params.is_alias && user ">
                    <button class="btn" style="z-index: 1; " @click="uicontrol.show_block_alias_invitations = true">
                        Invitations
                    </button>
                    <block_alias_invitations :aliases="params.block_aliases" v-if="uicontrol.show_block_alias_invitations" @hide_invitations="() => { uicontrol.show_block_alias_invitations = false }" @add_alias="data => { params.block_aliases.push(data) }" @delete_alias="deleteAlias($event)"></block_alias_invitations>
                </template>

            </div>
        </div>

        <tag_manager v-if="uicontrol.show_tag_manager" :board_tags="board.tags" :params="tag_manager_params" :nodes="board.data" @hide_tag_manager="() => {
                uicontrol.show_tag_manager = false;
                tag_manager_params.connected_node_index = null;
            }" @add_board_tag="addBoardTag($event)" @add_node_tag="addNodeTag($event)"></tag_manager>

        <div class="jtk-demo-canvas canvas-wide board jtk-surface" id="board" :class="{ 'blockchain_mode' : params.blockchain_mode, 'view_mode' : params.view_mode, 'edit_mode' : params.edit_mode, 'alias_mode' : params.alias_mode}">



            <div id="new_node_menu" v-if="new_node_menu.show" :style="{
                'left' : (new_node_menu.position.x + 'px'),
                'top' : (new_node_menu.position.y + 'px'),
            }">
                <span class="close" @click="hideNewNodeMenu()">X</span> <span class="title mb-4">Create new node</span>
                <hr>
                <ul>
                    <li v-for="component in available_node_components">
                        <div class="btn btn-outline-primary btn-sm fullwidth" v-text="component.readable_name" @click="() => {
                        newNode(component.type, new_node_menu.position.x, new_node_menu.position.y);
                        hideNewNodeMenu();
                    }"></div>
                    </li>
                </ul>
            </div>

            <!-- Draggable Element -->
            <div v-if="board.data.length"
                v-for="(node, nKey) in board.data"
                class="w"
                :class="node.type + '_node'"
                :scope="node.readable_name"
                :id="node.id"
                :style="{
                    'left' : (node.position.x + 'px'),
                    'top' : (node.position.y + 'px'),
                    'z-index' : (node.position.zindex || 'auto')
                }">

                <!-- Node component title (type) -->
                <span class="type" v-text="$options.components[node.type].readable_name"></span>

                <!-- ... button to show controls-->
                <button class="controls_btn" @click="toggleNodeControls(node.id)" v-if="params.edit_mode">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
                        <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
                    </svg>
                </button>

                <!-- Node controls component -->
                <node_controls v-if="params.edit_mode && controlsVisible(node.id)" :key="'controls_' + node.id" :node="node" @delete_node="deleteNode(node.id)" @show_tag_manager="() => {
                       tag_manager_params.connected_node_index = nKey;
                       uicontrol.show_tag_manager = true;
                       uicontrol.visible_node_controls = [];
                    }"></node_controls>

                <!-- Component inside node-->
                <component
                    :index="nKey"
                    :key="node.id"
                    :is="node.type"
                    :data="node.data"
                    :board_title="board.title"
                    :editable="params.edit_mode"
                    @update_node="updateNode($event)">
                </component>

                <span class="node_tags" v-if="Boolean(node.data.tags)">
                    <span v-if="Boolean(node.data.tags)" v-for="(node_tag, nodetagKey) in node.data.tags" :class="'tag tag-small tag-' + node_tag.color" v-text="node_tag.name"></span>
                </span>

            </div>

        </div>

        <div id="channel_container" class="in_board_channel" v-show="uicontrol.messaging.visible && params.edit_mode">
            <channel
				:channel="`boards.${board.identifier}`"
				:channel_type="'private'"
				:commenting_enabled="true"
				:scroll_bottom="true"
				ref="channel"
				@received_message="(e) => {
					if(!uicontrol.messaging.visible)
						uicontrol.messaging.blink = true;
				} "
			>
			</channel>
        </div>

        <div id="board-toolbar">
            <div>
                <div class="help border-right">
                    ?
                </div>
                <div class="timestamp border-right pl-3 pr-3" v-text="updated_at_format">
                    <!--12/09/2020 12:30-->
                </div>


                <!-- Save button -->
                <div class="actions pl-3" v-if="params.edit_mode">
                    <button class="btn btn-sm mt-2" :class="{ 'btn-outline-secondary' : !state.changed, 'btn-primary' : state.changed }" @click="update()">
                        <div class="spinner-border" role="status" style="width: 1rem; height: 1rem;" v-if="uicontrol.saving"></div>
                        <span v-text="uicontrol.saving ? 'Saving...' : 'Save'"></span>
                    </button>
                    <button class="btn btn-warning btn-sm ml-2 mt-2" @click="saveToBlockChain()" v-if="blockchain_version !== board.version">
                        Save to blockchain (
                        <span v-text="board.version - blockchain_version + ' versions behind '"></span> )
                        <div class="spinner-border" role="status" style="width: 1rem; height: 1rem;" v-if="uicontrol.saving_to_blockchain"></div>
                    </button>
                    <button class="btn btn-outline-secondary disabled btn-sm ml-2 mt-2" disabled v-else>
                        Blockchain version is up to date
                    </button>

                </div>

                <!-- Blockchain history -->
                <div class="blockchain-history d-inline-block" style="padding: 0px 6px 0px 6px;" @click="uicontrol.blockchain_history.visible = !uicontrol.blockchain_history.visible" v-if="Boolean(board.blocks) && board.blocks.length">

                    <div id="blockchain_history_container" v-show="uicontrol.blockchain_history.visible">
                        <div class="p-4">
                            <ul>
                                <li v-for="block in board.blocks">
                                    <a :href="'/dashboard/science/idea/block/' + block.block_id" target="_blank" v-text="DateTime.fromISO(block.created_at).toLocaleString(DateTime.DATETIME_MED)"></a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <button v-if="uicontrol.blockchain_history.visible">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path>
                        </svg>
                    </button>
                    <button v-if="!uicontrol.blockchain_history.visible">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"></path>
                        </svg>
                    </button>
                    Blockchain History
                </div>

				<div class="d-inline-block">
					<!--<nft_screenshots></nft_screenshots>-->
				</div>


            </div>
            <div>
                <div class="messaging pl-3 pr-3" @click="() => {

					uicontrol.messaging.visible = !uicontrol.messaging.visible
					uicontrol.messaging.blink = false;

					// Scroll to bottom
					if(uicontrol.messaging.visible)
						$refs.channel.scrollBottom()

				}" v-if="params.edit_mode">
					<div class="notify" v-if="uicontrol.messaging.blink">
						<span class="heartbit"></span>
						<span class="point"></span>
					</div>
                    <button class="p-0" v-if="uicontrol.messaging.visible">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path>
                        </svg>
                    </button>
                    <button class="p-0" v-if="!uicontrol.messaging.visible">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"></path>
                        </svg>
                    </button>
                    Messaging
                </div>
            </div>

        </div>

    </div>


</template>

<script>

import user_permissions from '@/components/sections/science/UserPermissions';
import {jsPlumb} from 'jsplumb';
import channel from '@/components/chat/Channel';
import text_box from './components/TextBox';
import deleted from './components/Deleted';
import origin from './components/Origin';
import file from './components/File';
import experiment from './components/Experiment';
import experiment_result from './components/ExperimentResult';
import block_alias_invitations from './components/BlockAliasInvitations';
import node_controls from './components/controls/NodeControls';
import tag_manager from './components/tags/TagManager';
// import nft_screenshots from './NFTScreenshots';
import {DateTime} from 'luxon';
import panzoom from 'panzoom';

export default {

    name: "Board",

    data: function () {
        return {
            offsetX: -10000 + window.innerWidth / 2 - 100,
            offsetY: -10000 + window.innerHeight / 2 - 170,
            tools: {
                'panzoom': null,
                'js_plumb': null
            },
            new_node_menu: {
                show: false,
                position: {
                    x: null,
                    y: null
                }
            },
            available_node_components: [],
            uicontrol: {
                saving: false,
                saving_to_blockchain: false,
                ready: false,
                messaging: {
                    visible: false,
					blink : false
                },
                blockchain_history: {
                    visible: false
                },
                show_block_alias_invitations: false,
                visible_node_controls: [],
                show_tag_manager: false,
				timestamp_authenticity_popup : {
					visible : false
				}
            },
            tag_manager_params: {
                connected_node_index: null
            },
            channel_container_element: null,
            blockchain_version: null,
            user: user,
            DateTime: DateTime,
            window: window,
            console: console,
            board: board,
            participants: participants,
            params: params,
            state : {
                changed : false
            }
        }
    },

    components: {
        node_controls,
        tag_manager,
        channel,
        deleted,
        origin,
        text_box,
        file,
        experiment,
        experiment_result,
        user_permissions,
        block_alias_invitations,
		// nft_screenshots
    },

    computed: {

        updated_at_format() {
            return DateTime.fromISO(this.board.updated_at).toLocaleString(DateTime.DATETIME_MED);
        },

    },

    watch: {
        board: {
            // This will let Vue know to look inside the array
            deep: true,

            // We have to move our method to a handler field
            handler() {
                this.state.changed = true;
            }
        }
    },

    created() {

        var vm = this;

        // If no nodes exist (new board)
        if (typeof this.board.data == "object" && !Object.keys(this.board.data).length) {

            // Push Origin node
            this.board.data = [{
                type: 'origin',
                id: 'origin',
                position: {
                    x: 10000,
                    y: 10000,
                },
                connections: [],
                data: {}
            }];

        }

    },

    mounted() {

        var vm = this;

        this.blockchain_version = this.board.latest_block == null ? 1 : this.board.latest_block.version;

        // Select channel container to optimize later use in panzoom
        this.channel_container_element = document.getElementById('channel_container');

        this.initBoardPanZoom();
        this.initJsPlumb();

        if (this.params.edit_mode)
            this.makeInteractive();

        this.makeConnections();
        this.populateAvailableNodeComponents();

        // Center to origin
        this.tools.panzoom.moveTo(-Math.abs(this.board.data[0].position.x) + window.innerWidth / 2 - (document.getElementById('origin').clientWidth / 2), -Math.abs(this.board.data[0].position.y) + window.innerHeight / 2 - 170);
        this.uicontrol.ready = true;

        // Ctrl + s
        $(window).bind('keydown', function (event) {

            if (event.ctrlKey || event.metaKey) {

                switch (String.fromCharCode(event.which).toLowerCase()) {

                    case 's':

                        event.preventDefault();

                        if (vm.params.edit_mode) {
                            vm.update();
                        } else {
                            Swal.fire('Unable to save', 'You are in view mode');
                        }

                        break;
                }

            }

        });


        // Listener for leaving page
		if(environment == 'production'){

			window.addEventListener("beforeunload", function (e) {

				// If not changed, return
				if(!vm.state.changed)
					return;

				var confirmationMessage = 'It looks like you have been editing something. '
					+ 'If you leave before saving, your changes will be lost.';

				(e || window.event).returnValue = confirmationMessage; //Gecko + IE
				return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.

			});

		}

		// Show chat when url contains #messaging
		if(window.location.hash == '#messaging')
			this.uicontrol.messaging.visible = true;

    },


    methods: {

        addNodeTag(tag) {
            console.log('Added node tag', tag);
        },

        addBoardTag(tag) {

            // this.board.tags = Object.assign(this.board.tags, tag);

            this.board.tags.push(tag);

        },

        // Determines if controls for specific node_id should be visible
        // (we don't want to store a property related to visibility inside of the node itself, because nodes get saved as they are
        controlsVisible(node_id) {

            return this.uicontrol.visible_node_controls.includes(node_id);

        },

        toggleNodeControls(node_id) {

            if (!this.uicontrol.visible_node_controls.includes(node_id)) {
                this.uicontrol.visible_node_controls.push(node_id);
            } else {
                this.uicontrol.visible_node_controls.splice(
                    this.uicontrol.visible_node_controls.indexOf(node_id),
                    1
                );
            }

        },

        deleteAlias(index) {

            this.params.block_aliases.splice(index, 1);
            this.uicontrol.show_block_alias_invitations = false;

            Vue.nextTick(() => {
                this.uicontrol.show_block_alias_invitations = true;
            });

        },

        updateNode(params) {
            Object.assign(this.board.data[params.index].data, params.data)
        },

        putNodeOnTop(id){

            // Make all z-indexes auto
            this.board.data.map(node => {
                node.position.zindex = 'auto';
            })


            let clicked_element = this.board.data.find(element => {
                    return element.id == id;
            });

            clicked_element.position.zindex = 100;


        },

        // Filter components to find node-compatible vue components
        populateAvailableNodeComponents() {
            this.available_node_components = Object.values(this.$options.components).filter((component) => component.show_in_menu);
        },

        // Initialize tool
        initBoardPanZoom() {

            var vm = this;

            // just grab a DOM element
            var element = document.querySelector('#board')

            // And pass it to panzoom
            this.tools.panzoom = panzoom(element, {
                bounds: true,
                boundsPadding: 0.1,
                beforeWheel: function (e) {
                    return true;
                },

                filterKey: function (/* e, dx, dy, dz */) {
                    // don't let panzoom handle this event:
                    return true;
                },

                beforeMouseDown: function (e) {
                    // returning true disables dragging

                    return e.target !== element || !element.contains(e.target);
                },

                zoomDoubleClickSpeed: 1
            });

            // panzoom(element, {
            //     beforeMouseDown: function(e) {
            //         // allow mouse-down panning only if altKey is down. Otherwise - ignore
            //         var shouldIgnore = !e.altKey;
            //         return shouldIgnore;
            //     }
            // });

        },

        // Initialize tool
        initJsPlumb() {

            var vm = this;

            // setup some defaults for jsPlumb.
            window.js_plumb = this.tools.js_plumb = jsPlumb.getInstance({
                Endpoint: ["Dot", {radius: 4}],
                Connector: "StateMachine",
                HoverPaintStyle: {stroke: "#ff0000", strokeWidth: 2},
                ConnectionOverlays: [
                    ["Arrow", {
                        location: 1,
                        id: "arrow",
                        length: 15,
                        foldback: 1
                    }],
                    ["Label", {label: "FOO", id: "label", cssClass: "aLabel"}]
                ],
                Container: "canvas"
            });

            this.tools.js_plumb.registerConnectionType("basic", {anchor: "Continuous", connector: "StateMachine"});

            window.jsp = this.tools.js_plumb;

        },

        // Add event listeners for various types of events
        makeInteractive() {

            var vm = this;

            var canvas = document.getElementById("board");
            var windows = jsPlumb.getSelector(".board .w");

            // bind a click listener to each connection; the connection is deleted. you could of course
            // just do this: this.tools.js_plumb.bind("click", this.tools.js_plumb.deleteConnection), but I wanted to make it clear what was
            // happening.
            this.tools.js_plumb.bind("click", function (c) {
                vm.tools.js_plumb.deleteConnection(c);
            });

            // bind a connection listener. note that the parameter passed to this function contains more than
            // just the new connection - see the documentation for a full list of what is included in 'info'.
            // this listener sets the connection's internal
            // id as the label overlay's text.
            this.tools.js_plumb.bind("connection", function (info) {

                if (!vm.uicontrol.ready)
                    return;

                vm.registerConnection(info.sourceId, info.targetId);
                info.connection.getOverlay("label").setLabel(info.connection.id);

            });


            this.tools.js_plumb.bind("connectionDetached", function (info) {
                vm.clearConnection(info.sourceId, info.targetId);
            });


            // bind a double click listener to "canvas"; add new node when this occurs.
            this.tools.js_plumb.on(canvas, "dblclick", function (e) {
                vm.new_node_menu.position.x = e.offsetX;
                vm.new_node_menu.position.y = e.offsetY;
                vm.new_node_menu.show = true;
            });


            // bind a right click listener to "canvas"; add new node when this occurs.
            this.tools.js_plumb.on(canvas, "contextmenu", function (e) {
                e.preventDefault();
                vm.new_node_menu.position.x = e.offsetX;
                vm.new_node_menu.position.y = e.offsetY;
                vm.new_node_menu.show = true;
            });

            // suspend drawing and initialise.
            this.tools.js_plumb.batch(function () {
                for (var i = 0; i < windows.length; i++) {
                    vm.initNode(windows[i], true);
                }
            });

        },

        // Initializes one element as a jsplumb node
        initNode(el) {

            var vm = this;

            // bind a double click listener to "canvas"; add new node when this occurs.
            this.tools.js_plumb.on(el, "click", function (e) {

                let node_id = null;

                if(!e.target.getAttribute('class') || !e.target.getAttribute('class').includes('w ')){ // If clicked inside the .w div, on the inner vue component

                    // Climb up the html tree and get the id from the .w div
                    let container = $(e.target).closest('.w');
                    node_id = container.attr('id');

                }else{ // If clicked on the .w div (grey area)

                    // Get the id from the clicked div
                    node_id = e.target.getAttribute('id');

                }

                if(node_id)
                    vm.putNodeOnTop(node_id);

            });

            vm.tools.js_plumb.draggable(el, {
                containment: true,
                stop: function (evt) {
                    // On Drag Stop, updatePosition(node_id, position_x, position_y)
                    vm.updatePosition(evt.el.id, evt.finalPos[0], evt.finalPos[1])
                }
            })

            vm.tools.js_plumb.makeSource(el, {
                filter: ".ep",
                anchor: "Continuous",
                connectorStyle: {stroke: "#BABCC1", strokeWidth: 2, outlineStroke: "transparent", outlineWidth: 4},
                connectionType: "basic",
                extract: {
                    "action": "the-action"
                },
                maxConnections: 30,
                onMaxConnections: function (info, e) {
                    alert("Maximum connections (" + info.maxConnections + ") reached");
                }
            });

            vm.tools.js_plumb.makeTarget(el, {
                dropOptions: {hoverClass: "dragHover"},
                anchor: "Continuous",
                allowLoopback: true
            });

            vm.tools.js_plumb.fire("jsPlumbDemoNodeAdded", el);

        },

        retireNode(node_id) {

            let plumb = this.tools.js_plumb;

            plumb.setSuspendEvents(true);
            plumb.setSuspendDrawing(true);

            // vm.tools.js_plumb.unbindContainer(node_id);
            // vm.tools.js_plumb.deleteObject(node_id);
            // vm.tools.js_plumb.deleteEveryConnection(node_id);

            plumb.removeAllEndpoints(node_id); // Works
            plumb.unmakeTarget(node_id).unmakeSource(node_id);

            plumb.setSuspendEvents(false);
            plumb.setSuspendDrawing(false);

            // vm.tools.js_plumb.destroy();
            // vm.tools.js_plumb.remove(node_id);
        },

        // Makes connections for existing nodes
        makeConnections() {

            var vm = this;

            // suspend drawing and initialise.
            this.tools.js_plumb.batch(function () {

                // Return if no nodes
                if (!vm.board.data.length)
                    return;

                for (var node of vm.board.data) {
                    for (var connection of node.connections) {

                        vm.tools.js_plumb.connect({source: node.id, target: connection, type: "basic"});

                    }
                }

            });

        },

        // Update the nodes array to reflect 1 new connection
        registerConnection(source_id, target_id) {
            let node = this.board.data.find(node => node.id == source_id);
            node.connections.push(target_id);
        },

        // Update the nodes array to reflect 1 less connection
        clearConnection(source_id, target_id) {

            console.log('Clearing connection', source_id, target_id);

            // Source node
            let node = this.board.data.find(node => node.id == source_id);

            // Find index of target node id in source node connections array
            let connection_index = node.connections.findIndex(item => {
                return item == target_id;
            });

            // Splice 1 element at index
            node.connections.splice(connection_index, 1);

        },

        // // Update the nodes array to reflect 1 change in position for a node
        updatePosition(node_id, position_x, position_y) {

            let node = this.board.data.find(node => node.id == node_id);
            node.position.x = position_x;
            node.position.y = position_y;

        },

        // Hide New Node Menu
        hideNewNodeMenu() {

            this.new_node_menu.show = false;
            this.new_node_menu.position.x = null;
            this.new_node_menu.position.y = null;

        },

        // Creates a new node (Vue component + js_plumb interactivity)
        newNode(type, position_x, position_y) {

            let node_id = jsPlumbUtil.uuid();

            let sample_data = Object.assign({}, this.$options.components[type].sample_data)
            sample_data.tags = [];

            let node = {
                type: type,
                id: node_id,
                position: {
                    x: position_x,
                    y: position_y,
                },
                connections: [],
                data: sample_data
            }

            this.board.data.push(node);

            this.$nextTick(() => {
                this.$nextTick(() => {
                    let node_element = document.getElementById(node_id);
                    this.initNode(node_element);
                });
            });

        },

        deleteNode(node_id) {

            var vm = this;

            Swal.fire({
                title: "Delete node?",
                text: "Connections and data will be deleted",
                icon: 'question',
                confirmButtonText: "Delete",
                cancelButtonText: "Cancel",
                showCancelButton: true,
            }).then((result) => {

                if (result.value) {

                    vm.retireNode(node_id);

                    // Remove connections tot this node from data
                    vm.board.data.forEach(n => {
                        n.connections = n.connections.filter(function (item) {
                            return item !== node_id;
                        });
                    });

                    // Change node type to deleted ( instead of removing the node, because JS Plumb relies on node indexes to function properly, and removing a node throws it off )
                    // Instead, we change the node type to deleted, which renders a hidden div
                    // On writing to database we filter out the deleted nodes so they don't get saved
                    let node_index = this.board.data.find(node => node.id == node_id).type = 'deleted';

                    Vue.nextTick(() => {
                        vm.$forceUpdate();
                    });

                }

            });

        },

        update() {

            this.uicontrol.saving = true;

            // Filter out deleted nodes
            let filtered_nodes = this.board.data.filter(node => node.type !== 'deleted');

            axios.put('/dashboard/science/idea/' + this.board.identifier, {
                title: this.board.title,
                description: this.board.description,
                data: filtered_nodes,
                tags: this.board.tags
            }).then(response => {

                this.uicontrol.saving = false;
                this.board.version++;
                this.updated_at = response.data.updated_at;
                this.$nextTick(() => {
                    this.state.changed = false;
                });

            });

        },

        saveToBlockChain() {

            this.uicontrol.saving_to_blockchain = true;
            axios.post('/dashboard/science/idea/' + this.board.identifier + '/toblockchain').then(response => {
                this.uicontrol.saving_to_blockchain = false;
                this.blockchain_version = this.board.version;
                this.board.blocks.push(response.data);
            });

        }


    }

}
</script>

<style scoped>

#notifications {
	list-style-type: none;
	position: absolute;
	left: 10px;
	top: 5px;
}

</style>

