<template>

	<form id="science-experiment-result" action="" v-if="uicontrol.ready" onkeydown="return event.key != 'Enter';">

		<div class="card experiment-title-wrapper mb-3">

			<div class="top">
				<div class="group">
					<h3>Title: {{ title }}</h3>
				</div>

				<div class="group d-flex flex-column">
					<div>
						<a class="btn btn-delete-exp ml-1" @click="deleteResult()" v-if="Boolean(result.identifier) && editable">Delete</a>
						<a v-if="editable && result.identifier" :href="`/dashboard/science/experiments/${experiment.identifier}/results/${result.identifier}`" class="btn btn-edit-exp ml-1">View</a>
						<button type="button" @click="save($event)" v-if="editable" class="btn btn-edit-exp ml-1" :class="{ loading : uicontrol.saving }">
							<div data-loader="circle-side"></div>
							{{ !Object.keys(result).length ? 'Create result' : 'Save' }}
						</button>
						<a v-if="!editable && is_admin && !Boolean(result.signed_by_user_id)" :href="`/dashboard/science/experiments/${experiment.identifier}/results/${result.identifier}/edit`" class="btn btn-edit-exp ml-1">Edit
							Result</a>
					</div>
				</div>
			</div>

			<div class="top mb-4" v-if="editable">

				<div class="group">

					<div class="mr-2">
						<label for="">Title</label>
						<input class="form-control" name="result_title" id="result_title" type="text" placeholder="Title" v-model="title" v-validate="'required|min:3'" data-vv-as="Title">
						<span class="error_label text-danger">{{ errors.first('result_title') }}</span>
					</div>

					<div class="mr-2">
						<label for="">Section</label>
						<input class="form-control" name="result_section" id="result_section" type="text" placeholder="Section" v-model="section" v-validate="'required|min:3'" data-vv-as="Section">
						<span class="error_label text-danger">{{ errors.first('result_section') }}</span>
					</div>

					<div class="mr-2">
						<label for="">M4Q Chapter</label>
						<input class="form-control" name="result_m4q_chapter" id="result_m4q_chapter" type="text" placeholder="M4Q Chapter" v-model="m4q_chapter" v-validate="'required|min:3'" data-vv-as="M4Q Chapter">
						<span class="error_label text-danger">{{ errors.first('result_m4q_chapter') }}</span>
					</div>

					<div class="mr-2">
						<label for="">Description</label>
						<input class="form-control" name="result_description" id="result_description" type="text" placeholder="Description" v-model="description" v-validate="'required|min:3'" data-vv-as="Description">
						<span class="error_label text-danger">{{ errors.first('result_description') }}</span>
					</div>

					<div class="pr-1">
						<label for="">Start Date</label>
						<flat-pickr v-model="start_date" :config="flatpickr_config" placeholder="Start Date"></flat-pickr>
					</div>

					<div class="pl-1">
						<label for="">End Date</label>
						<flat-pickr v-model="end_date" :config="flatpickr_config" placeholder="End date"></flat-pickr>
					</div>

					<div class="form-group pl-1 mb-0">
						<label for="accuracy">Accuracy</label>
						<select class="form-control" name="accuracy" id="" v-model="end_date_accuracy">
							<option value="exact">Exact</option>
							<option value="approximate">Approximate</option>
						</select>
					</div>

				</div>

			</div>

			<div class="bottom">

				<p><b>Section</b>: {{ result.section }}</p>
				<p><b>M4Q Chapter</b>: {{ result.m4q_chapter }}</p>
				<p><b>Description</b>: {{ result.description }}</p>

				<p><b>Author:</b> {{ experiment.author.name }}</p>
				<p><b>Lab:</b> {{ experiment.facility.country.name }}</p>
				<p><b>Start:</b> {{ result.start_date ? DateTime.fromSQL(result.start_date).toFormat('dd LLL yyyy') : 'n/a' }}</p>
				<p><b>End:</b> {{ result.end_date ? DateTime.fromSQL(result.end_date).toFormat('dd LLL yyyy') : 'n/a' }}</p>

			</div>

			<template v-if="Boolean(result.id)">

				<PurchaseApprovalRequestsTable :items="result.purchase_approval_requests"/>

				<div class="mt-2" v-if="editable">
					<a class="btn btn-rounded btn-white ml-0" :href="'/dashboard/approval-requests/store-for-experiment-result/' + result.identifier">
						Create a purchase request </a>
				</div>

			</template>

		</div>


		<div class="card-tabs pb-5" v-if="Object.keys(result).length">

			<!-- Result is empty object? -> we are on the create page -->
			<nav>
				<div class="nav nav-tabs" id="nav-tab" role="tablist">
					<a class="nav-link" v-if="!hidden_tabs.includes(Headertab.id)" v-for="(Headertab, HeadertabKey) in template.tabs" :key="HeadertabKey" :class="{ active : (uicontrol.active_tab == Headertab.id || (uicontrol.active_tab == null && HeadertabKey == 0) ) }" :id="Headertab.id" @click="uicontrol.active_tab = Headertab.id; save($event)" aria-selected="true">{{
							Headertab.name
						}}</a>
				</div>
			</nav>

			<div class="tab-content" id="nav-tabContent">
				<div class="tab-pane fade show active pt-2" :key="BodytabKey" v-for="(Bodytab, BodytabKey) in template.tabs" v-show="uicontrol.active_tab == Bodytab.id || (uicontrol.active_tab == null && BodytabKey == 0)">
					<component v-for="field in Bodytab.fields" :is="'rf_' + field.type" :result_identifier="result.identifier" :tab="Bodytab" :field="field" :val="getData(Bodytab, field)" :key="Bodytab.id + field.data_id" :editable="editable" :is_author="is_author" :can_participate="can_participate" :messaging_channel="`experiment_results.${result.identifier}`" @update="setData($event)" @save="save()"></component>
				</div>
			</div>
		</div>

	</form>

</template>


<script>

import {DateTime} from "luxon";
import rf_image from '@/components/sections/reusable/field_types/Image';
import rf_input_text from '@/components/sections/reusable/field_types/InputText';
import rf_textarea from '@/components/sections/reusable/field_types/TextArea';
import rf_messaging_channel from '@/components/sections/reusable/field_types/MessagingChannel';
import rf_ordered_list from '@/components/sections/reusable/field_types/OrderedList';
import rf_select from '@/components/sections/reusable/field_types/Select';
import rf_date from '@/components/sections/reusable/field_types/Date';
import rf_file from '@/components/sections/reusable/field_types/File';
import PurchaseApprovalRequestsTable from "./PurchaseApprovalRequestsTable.vue";

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {

	name: 'ExperimentResult',

	data: function () {
		return {
			title: null,
			section: null,
			m4q_chapter: null,
			description: null,
			start_date: null,
			end_date: null,
			end_date_accuracy: 'exact',
			flatpickr_config: {
				dateFormat: "Y-m-d H:i:ss",
				altInput: true,
				altFormat: "F j, Y"
			},
			DateTime: DateTime,
			data: {},
			uicontrol: {
				active_tab: null,
				ready: false,
				saving: false
			},
			experiment_type_result_template: {
				other: 'template_a',
				in_vitro_and_ex_vivo: 'template_a',
				in_vitro: 'template_a',
				clinical: 'template_b'
			},
			templates: {
				template_a: {
					"name": "Template A",
					"tabs": [
						{
							"name": "Overview",
							"id": "overview",
							"fields": [
								{
									"label": "Model",
									"type": "input_text",
									"data_id": "model"
								},
								{
									"label": "Responsability",
									"type": "input_text",
									"data_id": "responsability"
								},
								{
									"label": "Results",
									"type": "textarea",
									"data_id": "results"
								}
							],
						},
						{
							"name": "Chart",
							"id": "chart",
							"fields": [
								{
									"label": "Chart",
									"type": "image",
									"data_id": "chart_image"
								},
								{
									"label": "Comments",
									"type": "input_text",
									"data_id": "comments"
								},
							],
						},
						{
							"name": "Data Table",
							"id": "data_table",
							"fields": [
								{
									"label": "Table",
									"type": "textarea",
									"data_id": "data_table"
								}
							],
						},
						{
							"name": "Data Results",
							"id": "data_results",
							"fields": [
								{
									"label": "Results",
									"type": "textarea",
									"data_id": "data_results"
								}
							],
						},
						{
							"name": "Comments",
							"id": "comments",
							"fields": [
								{
									"label": "Comments",
									"type": "messaging_channel",
									"data_id": null
								}
							],
						},
						{
							"name": "Actions",
							"id": "actions",
							"fields": [
								{
									"label": "Actions",
									"type": "ordered_list",
									"data_id": "actions"
								}
							],
						},
						{
							"name": "Draft Report",
							"id": "draft_report",
							"fields": [
								{
									"label": "File",
									"type": "file",
									"data_id": "upload_pdf_draft",
									"params": {
										"accepted_mime_types": ["application/pdf"],
										"description": "Accepted file types: PDF"
									}
								}
							],
						},
						{
							"name": "Final Report",
							"id": "final_report",
							"fields": [
								{
									"label": "File",
									"type": "file",
									"data_id": "upload_pdf_final",
									"params": {
										"accepted_mime_types": ["application/pdf"],
										"description": "Accepted file types: PDF"
									}
								}
							],
						}
					]
				},

				template_b: {
					"name": "Template B",
					"tabs": [
						{
							"name": "Overview",
							"id": "overview",
							"fields": [
								{
									"label": "Phase",
									"type": "select",
									"data_id": "phase",
									"options": [
										{
											"label": "Select",
											"value": null
										},
										{
											"label": "1",
											"value": 1
										},
										{
											"label": "2",
											"value": 2
										},
										{
											"label": "2/3",
											"value": 23
										},
										{
											"label": "3",
											"value": 3
										},
										{
											"label": "4",
											"value": 4
										}
									]
								},
								{
									"label": "Responsability",
									"type": "input_text",
									"data_id": "responsability"
								},
								{
									"label": "Subtitle",
									"type": "input_text",
									"data_id": "subtitle"
								},
								{
									"label": "Host",
									"type": "input_text",
									"data_id": "host"
								},
								{
									"label": "Virus",
									"type": "input_text",
									"data_id": "virus_name"
								},
								{
									"label": "Primary Objective",
									"type": "textarea",
									"data_id": "primary_objective"
								},
								{
									"label": "Secondary Objective",
									"type": "textarea",
									"data_id": "secondary_objective"
								},
								{
									"label": "Study Design",
									"type": "textarea",
									"data_id": "study_design"
								},
								{
									"label": "Statistical Analysis Plan",
									"type": "textarea",
									"data_id": "statistical_analysis_plan"
								}
							],
						},
						{
							"name": "Timings",
							"id": "timings",
							"fields": [
								{
									"label": "Timeline: First Patient, First Visit",
									"type": "date",
									"data_id": "timeline_first_patient_first_visit"
								},
								{
									"label": "Timeline: Last Patient Visit",
									"type": "date",
									"data_id": "timeline_last_patient_visit"
								},
								{
									"label": "Database lock",
									"type": "date",
									"data_id": "database_lock"
								},
								{
									"label": "Study report",
									"type": "date",
									"data_id": "study_report"
								},
							],
						},
						{
							"name": "Chart",
							"id": "chart",
							"fields": [
								{
									"label": "Primary Result",
									"type": "textarea",
									"data_id": "primary_result"
								},
								{
									"label": "Secondary Result",
									"type": "textarea",
									"data_id": "secondary_result"
								}
							],
						},
						{
							"name": "Data Table",
							"id": "data_table",
							"fields": [
								{
									"label": "Results",
									"type": "textarea",
									"data_id": "results"
								},
								{
									"label": "Upload CSV",
									"type": "file",
									"data_id": "upload_csv",
									"params": {
										"accepted_mime_types": ["text/csv"],
										"description": "Accepted file types: CSV"
									}
								},
							],
						},
						{
							"name": "Conclusion",
							"id": "conclusion",
							"fields": [
								{
									"label": "Conclusion",
									"type": "textarea",
									"data_id": "conclusion"
								}
							],
						},
						{
							"name": "Comments",
							"id": "comments",
							"fields": [
								{
									"label": "Comments",
									"type": "messaging_channel",
									"data_id": null
								}
							],
						},
						{
							"name": "Actions",
							"id": "actions",
							"fields": [
								{
									"label": "Actions",
									"type": "ordered_list",
									"data_id": "actions"
								}
							],
						},
						{
							"name": "Original Documentation",
							"id": "original_documentation",
							"fields": [
								{
									"label": "File",
									"type": "file",
									"data_id": "upload_pdf",
									"params": {
										"accepted_mime_types": ["application/pdf"],
										"description": "Accepted file types: PDF"
									}
								}
							],
						},
					]
				}
			}
		}
	},

	components: {
		rf_image,
		rf_input_text,
		rf_textarea,
		rf_messaging_channel,
		rf_ordered_list,
		flatPickr,
		rf_select,
		rf_date,
		rf_file,
		PurchaseApprovalRequestsTable
	},

	props: [
		'experiment',
		'result',
		'editable',
		'can_participate',
		'is_admin',
		'is_author',
		'hidden_tabs'
	],

	computed: {
		template() {
			return this.templates[this.experiment_type_result_template[this.experiment.type]];
		}
	},

	mounted() {

		this.buildDataFromTemplate();

		this.section = this.result.section;
		this.m4q_chapter = this.result.m4q_chapter;
		this.title = this.result.title;
		this.description = this.result.description;
		this.start_date = this.result.start_date;
		this.end_date = this.result.end_date;

		if (this.result)
			this.loadDataFromResult();

		this.uicontrol.ready = true;

		if (window.location.hash == '#comments')
			this.uicontrol.active_tab = 'comments';

	},

	methods: {


		createPurchaseRequest() {


		},

		buildDataFromTemplate() {

			// Iterate tabs
			for (let tab of this.template.tabs) {
				for (let field of tab.fields) {
					// Set null property in this.data
					if (typeof this.data[this.composePropertyName(tab, field)] == 'undefined')
						this.data[this.composePropertyName(tab, field)] = null;
				}
			}

		},

		loadDataFromResult() {
			Object.assign(this.data, this.result.data);
		},

		getData(tab, field) {
			return this.data[this.composePropertyName(tab, field)];
		},

		setData(event) {
			Vue.set(this.data, this.composePropertyName(event.tab, event.field), event.value);
			this.$forceUpdate();
		},

		composePropertyName(tab, field) {
			return _.replace(tab.name + '_' + field.data_id, ' ', '_')
		},

		save(event = null) {

			console.log(event);

			if (event)
				event.preventDefault();

			if (_.isEmpty(this.result)) {
				this.store();
			} else {
				this.update();
			}

		},

		deleteResult() {

			var vm = this;

			Swal.fire({
				title: 'Delete result?',
				text: 'This will delete all data from this result. This action cannot be undone',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes'
			}).then((result) => {
				if (result.isConfirmed) {

					axios.delete('/dashboard/science/experiments/' + vm.experiment.identifier + '/results/' + vm.result.identifier).then(function () {
						// window.location.href = '/dashboard/science/experiments/' + vm.experiment.identifier;
					});
				}
			});

		},

		store() {


			this.$validator.validate().then(valid => {

				if (!valid) return;


				axios.post(`/dashboard/science/experiments/${this.experiment.identifier}/results`, {
					title: this.title,
					section: this.section,
					m4q_chapter: this.m4q_chapter,
					description: this.description,
					start_date: this.start_date,
					end_date: this.end_date,
					end_date_accuracy: this.end_date_accuracy,
					data: this.data
				}).then(response => {

					// Go to edit page
					if (response.data.success)
						window.location.href = baseurl + '/dashboard/science/experiments/' + this.experiment.identifier + '/results/' + response.data.result_identifier + '/edit';

				});


			});


		},

		update() {

			if (!this.editable)
				return;

			this.$validator.validate().then(valid => {

				if (!valid) return;

				this.uicontrol.saving = true;

				axios.put(`/dashboard/science/experiments/${this.experiment.identifier}/results/${this.result.identifier}`, {
					title: this.title,
					section: this.section,
					m4q_chapter: this.m4q_chapter,
					description: this.description,
					start_date: this.start_date,
					end_date: this.end_date,
					end_date_accuracy: this.end_date_accuracy,
					data: this.data
				}).then(response => {
					this.uicontrol.saving = false;
				});


			});

		}


	}

}

</script>