<template>
    <div>
        <template v-if="editable">
            <div class="ep" action="begin"></div>
            <textarea
                name="content"
                spellcheck="false"
                cols="6" rows="6"
                placeholder="text here..."
                :id="'textarea_node_index_' + index"
                :style="{ width: data.size.width + 'px', height: data.size.height + 'px' }"
                 v-model="data.content"></textarea>
        </template>
        <div v-else="" class="text-container" :style="{ width: data.size.width + 'px', height: data.size.height + 'px' }">
            <p v-html="data.content"></p>
        </div>

    </div>
</template>

<script>
export default {
    name: "TextBox",
    props: ['index','data','editable'],
    data : function(){
        return {

        }
    },
    mounted(){

        var vm = this;

        if(!this.editable)
            return;

        let resizeObserver = new ResizeObserver(function(param){

            // Firefox and Chrome have different object structures
            vm.data.size.width = param[0].borderBoxSize.inlineSize ? param[0].borderBoxSize.inlineSize : param[0].borderBoxSize[0].inlineSize;
            vm.data.size.height = param[0].borderBoxSize.blockSize ? param[0].borderBoxSize.blockSize : param[0].borderBoxSize[0].blockSize;

        });

        resizeObserver.observe(
            document.getElementById('textarea_node_index_' + this.index)
        );

    },
    methods : {

    },
    // Node specific properties
    readable_name: "Text Box",
    type : 'text_box',
    sample_data : {
        content : '',
        tags : [],
        size : {
            width : null,
            height : null
        }
    },
    show_in_menu : true
}
</script>

<style scoped>

</style>
