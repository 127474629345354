<template>
    <div class="search-alerts__popup" id="tag_manager">
        <div class="search-alerts__popup-overlay"></div>
        <div class="search-alerts__popup-content" style="width: 400px; padding: 30px; top: 25%;">
            <div class="row">
                <div class="col">
                    <h3 class="search-alerts__popup-title">Tags</h3>
                </div>
                <div class="col">
                    <i name="close" class="fa fa-times float-right search-alerts__icon cursor-pointer p-3" @click="$emit('hide_tag_manager')"></i>
                </div>
            </div>

            <!-- Tags -->
            <template v-if="!uicontrol.show_new_tag_form">

                <p class="font-weight-500">Applied tags</p>
                <div id="applied_tags">
                    <div class="tag-large" :class="'tag-' + tag.color" :key="'applied_tags_' + tagKey"  v-for="(tag, tagKey) in connected_node_tags" v-if="connected_node_tags.length" @click="removeNodeTag(tag)">
                        <span v-text="tag.name"></span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                    <p v-if="!connected_node_tags.length" class="text-grey"><i>No tags applied</i></p>
                </div>

                <p class="mt-4 font-weight-500">Available tags</p>
                <div id="available_tags">
                    <div class="tag-large" :class="'tag-' + tag.color" :key="'available_tags_' + tagKey"  v-for="(tag, tagKey) in available_board_tags" v-if="available_board_tags.length" @click="connected_node_tags.push(tag)">
                        <span v-text="tag.name"></span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>
                    </div>
                    <p v-if="!available_board_tags.length" class="text-grey"><i>No tags available</i></p>
                </div>

            </template>


            <!-- Add new tag -->
            <button class="btn btn-primary btn-rounded mt-4" v-if="!uicontrol.show_new_tag_form" @click="uicontrol.show_new_tag_form = true">
                Create new tag
            </button>
            <div class="container p-0" v-if="uicontrol.show_new_tag_form">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="name">Tag name (optional)</label>
                            <input type="text" id="name" name="name" class="form-control" v-model="form.name" v-validate="'required'">
                            <span>{{ errors.first('name') }}</span>
                        </div>
                    </div>
                    <div class="col-12">

                        <div
                            v-for="(color, colorKey) in available_colors"
                            :class="{
                                ['color-swatch tag-' + color] : true,
                                ['selected'] : (form.color == color)
                                }"
                            @click="form.color = color">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                            </svg>

                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group mt-3 d-flex justify-content-end">
                            <button class="btn btn-outline-secondary btn-rounded mr-3" style="width: 30%" @click="$emit('hide_tag_manager')">Cancel</button>
                            <button class="btn  btn-rounded btn-primary" style="width: 70%" @click="addBoardTag()">Create</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {

    name: "TagManager",

    props: ['board_tags', 'params', 'nodes'],

    data : function(){
        return {
            form : {
                name : null,
                color : 'black'
            },
            available_colors : ['green','yellow','orange','red','purple','blue','pink','sky','lime','black'],
            uicontrol : {
                show_new_tag_form : false
            }
        }
    },

    computed : {

        connected_node(){
            return this.nodes[this.params.connected_node_index];
        },

        connected_node_tags(){
            return this.connected_node.data.tags;
        },

        // Board tags minus node tags
        available_board_tags(){

            // filter board tags
            let filtered_tags = this.board_tags.filter(board_tag => {

                // if at least 1 element in node_tags has the same ID as this board_tag, return false, to filter it out of available board tags
                return !this.connected_node_tags.filter(node_tag => node_tag.id == board_tag.id).length;

            });

            return filtered_tags;
        },

    },

    methods : {

        addBoardTag(){

            let new_tag = Object.assign({
                id : new Date().getTime()
            }, this.form);

            this.$emit('add_board_tag', new_tag);

            this.$nextTick(() => {
                this.form.name = null;
                this.form.color = 'black';
                this.uicontrol.show_new_tag_form = false;
            })

        },

        removeNodeTag(tag){

            this.connected_node_tags.splice(this.connected_node_tags.findIndex(nt => nt.id == tag.id), 1);

        }

    }

}
</script>

<style scoped>

</style>
