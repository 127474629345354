<template>

    <main>

        <div class="modal file-detail-modal show" :id="'file-detail-modal' + field_value.identifier" tabindex="-1" role="dialog" v-if="field_value">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" v-text="prettyFileName"></h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <object :data="fileUrl + '#toolbar=0&navpanes=0&scrollbar=0'" type="application/pdf" style="width: 100%; height: 100%;">
                            <embed :src="fileUrl + '#toolbar=0&navpanes=0&scrollbar=0'" type="application/pdf" />
                        </object>
                    </div>
                </div>
            </div>
        </div>

        <a href="#" @click="showModal($event)" v-if="field_value" v-text="prettyFileName"></a>

    </main>

</template>


<script>
export default {
    name: "ApplicationPdfViewer",
    props : [
        'field_value'
    ],

    computed: {

        fileUrl() {
            return (typeof this.field_value.content_base64 == 'undefined') ? '/file/' + this.field_value.identifier : window.location.href + '/experiment_result/file/' + this.field_value.identifier
        },

        prettyFileName(){
            return this.field_value.name.split('/')[1];
        }

    },

    methods : {

        showModal(event){

            event.preventDefault();
            $('#file-detail-modal' + this.field_value.identifier).modal('show');

        },

    }
}
</script>

<style scoped>

</style>
