<!--suppress XmlInvalidId -->
<template>
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div id="approval_request_dashboard" v-if="uicontrol.ready">
						<div class="container">
							<div class="row">
								<div class="col-12 d-lg-flex justify-content-between border-1 mb-4 mt-2">
									<h3 class="title mb-3">Product</h3>
									<button v-if="is_admin" :class="{ loading : uicontrol.saving }" class="btn btn-rounded btn-white" @click="save($event)">
										<div data-loader="circle-side"></div>
										Save
									</button>
								</div>
							</div>
						</div>

						<div class="container">
							<div class="row mb-4">

								<div class="col-12 d-flex mb-4">
									<div class="form-group mr-2 mb-0" style="width: 60%">
										<label class="form-label font-weight-500" for="description">Name<sup>*</sup></label>
										<input id="name" v-model="product.name" v-validate="'required'" class="form-control" name="name" placeholder="" type="text">
										<span class="text-danger">{{ errors.first('name') }}</span>
									</div>

									<div class="form-group mr-2 mb-0" style="width: 60%">
										<label class="form-label font-weight-500" for="description">Description<sup>*</sup></label>
										<input id="description" v-model="product.description" v-validate="'required'" class="form-control" name="description" placeholder="" type="text">
										<span class="text-danger">{{ errors.first('description') }}</span>
									</div>
								</div>

								<div class="col-12 d-flex">

									<div class="form-group mr-2 mb-0" style="width: 60%">
										<label class="form-label font-weight-500" for="description">SKU<sup>*</sup></label>
										<input id="sku" v-model="product.sku" v-validate="'required'" class="form-control" name="sku" placeholder="" type="text">
										<span class="text-danger">{{ errors.first('sku') }}</span>
									</div>

									<div class="form-group mr-2 mb-0" style="width: 60%">
										<label class="form-label font-weight-500" for="description">Width (mm)<sup>*</sup></label>
										<input id="width" v-model="product.width" v-validate="'required'" class="form-control" name="width" placeholder="" type="text">
										<span class="text-danger">{{ errors.first('width') }}</span>
									</div>

									<div class="form-group mr-2 mb-0" style="width: 60%">
										<label class="form-label font-weight-500" for="description">Length (mm)<sup>*</sup></label>
										<input id="length" v-model="product.length" v-validate="'required'" class="form-control" name="length" placeholder="" type="text">
										<span class="text-danger">{{ errors.first('length') }}</span>
									</div>

									<div class="form-group mr-2 mb-0" style="width: 60%">
										<label class="form-label font-weight-500" for="description">Height (mm)<sup>*</sup></label>
										<input id="height" v-model="product.height" v-validate="'required'" class="form-control" name="height" placeholder="" type="text">
										<span class="text-danger">{{ errors.first('height') }}</span>
									</div>

									<div class="form-group mr-2 mb-0" style="width: 60%">
										<label class="form-label font-weight-500" for="description">Weight (Kg)<sup>*</sup></label>

										<float_input
											:id="'unit_price'"
											:name="'unit_price'"
											:val="product.weight" @update="product.weight = $event"
											:decimals="2"
											:disabled="false">
										</float_input>

										<span class="text-danger">{{ errors.first('weight') }}</span>
									</div>

									<div class="form-group mr-2 mb-0" style="width: 60%">
										<label class="form-label font-weight-500" for="description">Volume (ml)</label>
										<input id="volume" v-model="product.volume" class="form-control" name="volume" placeholder="" type="text">
										<span class="text-danger">{{ errors.first('volume') }}</span>
									</div>

								</div>
							</div>
						</div>

						<div class="container">
							<div class="row">
								<div class="col-12">
									<h5>Stock Items</h5>
								</div>
								<div class="col-12">
									<ul class="list-group">
										<li class="list-group-item cursor-default d-none d-md-none d-lg-block">
											<div class="container">
												<div class="row">
													<div class="col-1">
														<span class="font-weight-500 text-dark-grey">Amount</span>
													</div>
													<div class="col-2 text-center">
														<span class="font-weight-500 text-dark-grey">Batch ID</span>
													</div>
													<div class="col-1">
														<span class="font-weight-500 text-dark-grey">Location</span>
													</div>
													<div class="col-2 text-center">
														<span class="font-weight-500 text-dark-grey">Author</span>
													</div>
													<div class="col-2">
														<span class="font-weight-500 text-dark-grey">Status</span>
													</div>
													<div class="col-2">
														<span class="font-weight-500 text-dark-grey">Data</span>
													</div>
												</div>
											</div>
										</li>
										<li v-for="(item, itemKey) in stock_items" v-if="stock_items.length" class="list-group-item cursor-default">
											<div class="container">
												<div class="row pt-2 pb-2">

													<div class="col-1">
														<a :href="'/dashboard/product/'">
															<span class="font-weight-500 white-space-nowrap">{{
																	item.amount
																}}</span> </a> <br>
														<span class="small">{{ item.location_name }}</span>
													</div>

													<div class="col-lg-2 text-center">
                                                        <span class="text-light-grey white-space-nowrap">
                                                            {{ item.batch }}
                                                        </span>
													</div>

													<div class="col-lg-1">
                                                        <span class="text-light-grey white-space-nowrap">
                                                            {{ item.location.label }}
                                                        </span>
													</div>

													<div class="col-lg-2 text-center">
                                                        <span class="font-weight-500 white-space-nowrap">
                                                            {{ item.author.name }}
                                                        </span>
													</div>

													<div class="col-lg-2">
                                                        <span class="font-weight-500 white-space-nowrap">
                                                            {{
																available_status_options.find(e => e.id == item.status).title
															}}
                                                        </span>
													</div>
													<div class="col-lg-2">
                                                        <span class="font-weight-500 white-space-nowrap">
                                                            <template v-if="!item.data.elements">No data</template>
                                                            <template v-else>
                                                                <ul class="pl-0 list-style-none">
                                                                    <li v-for="element in item.data.elements">
                                                                        {{ element.title }} : {{ element.value }}
                                                                    </li>
                                                                </ul>
                                                            </template>
                                                        </span>
													</div>
												</div>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>

import {DateTime} from "luxon";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Swal from "sweetalert2";

export default {
	name: "Product",
	data: function () {
		return {

			is_admin: is_admin,

			filters: {
				keywords: '',
				type_id: null,
				stock_id: null,
				urgency_id: null,
				date_range_start: null,
				date_range_end: null,
				limit: 1500
			},

			available_type_options: [
				{
					title: 'Product',
					id: 'product',
					permission_prerequisite: null
				}
			],

			available_status_options: [
				{
					title: 'In stock',
					id: 'in_stock'
				},
				{
					title: 'Out of stock',
					id: 'out_of_stock'
				},
				{
					title: 'Shipped',
					id: 'shipped'
				},
				{
					title: 'Reserved',
					id: 'reserved'
				},
				{
					title: 'Split',
					id: 'split'
				}

			],

			any_option: {
				title: 'Any',
				id: null
			},

			flatpickr_config: {
				dateFormat: "Y-m-d H:i:ss",
				altInput: true,
				altFormat: "F j, Y"
			},

			stock_items: [],

			uicontrol: {
				ready: false,
				saving: false
			},

			product : null,

		}

	},

	props: [
		'user_permissions',
		'preloaded_product'
	],

	computed: {

		filter_stock_options() {
			let options = this.available_stock_options.slice(0);
			options.unshift(this.any_option);
			return options;
		},

		filter_type_options() {
			let options = this.available_type_options.slice(0);
			options.unshift(this.any_option);
			return options;
		},

		filter_urgency_options() {
			let options = this.available_urgency_options.slice(0);
			options.unshift(this.any_option);
			return options;
		},

		params() {

			let params = Object.assign({}, this.filters)

			if (params.keywords == '')
				delete params.keywords;

			// Add offset
			params.offset = this.items.length ? this.items.length + 1 : 0;

			return params

		}


	},

	watch: {

		filters: {
			deep: true,
			handler: _.debounce(function () {

					this.items = [];
					this.get().then(response => {
						this.items = response.data.results;
					});
				},

				500 // debounce time

			)
		}

	},

	components: {
		flatPickr
	},

	mounted() {

		this.product = this.preloaded_product;
		this.stock_items = this.product.stock_items;
		this.uicontrol.ready = true;


	},

	methods: {

		col() {
			return this.is_admin ? 'col-lg-5' : 'col-lg-6';
		},

		deleteItem(item, itemKey) {


			Swal.fire({
				title: 'Delete?',
				text: "This is not reversible!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {

				if (result.isConfirmed) {

					axios.delete('/dashboard/approval-requests/delete/' + item.identifier).then(response => {
						this.items.splice(itemKey, 1);
					});

				}
			})


		},

		get: function () {
			return axios.get(`/dashboard/product/${this.product.identifier}/stock_item`, {
				params: this.params
			});
		},

		formatTimeStamp(timestamp) {
			return DateTime.fromISO(timestamp).toFormat('d LLL yyyy H:mm');
		},

		save(event) {

			event.preventDefault();

			var vm = this;

			vm.$validator.validate().then(valid => {

				if (valid) {

					// Fake saving
					vm.uicontrol.saving = true;
					setTimeout(function () {
						vm.uicontrol.saving = false;
					}, 500);

					axios.put('/dashboard/product/' + vm.product.identifier, vm.product).then(response => {
						window.location.href = '/dashboard/product';
					});

					return vm;

				}

			});

		},

	},
}
</script>

<style scoped>

</style>
