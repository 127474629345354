<template>
    <div class="search-alerts__popup">
        <div class="search-alerts__popup-overlay"></div>
        <div class="search-alerts__popup-content">
            <div class="row">
                <div class="col">
                    <h3 class="search-alerts__popup-title">Subscribers</h3>
                </div>
                <div class="col">
                    <i class="fa fa-times float-right search-alerts__icon" @click="closeModal()"></i>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-3">
                    <strong>Add users</strong>
                    <select v-model="selectedUser" class="form-control">
                        <option v-for="user in users" v-bind:key="user.id" :value="user.id">
                            {{user.name}}
                        </option>
                    </select>
                </div>
                <div class="col">
                    <p class="search-alerts__subscriber" v-for="(user, key) in subscribers" v-bind:key="key">
                        {{user.name}} <i class="fa fa-times" @click="deleteSubscriber(user)"></i>
                    </p>
                </div>
            </div>
            <button class="btn-filled btn-filled-rounded btn-filled-right" @click="closeModal()">Save</button>
        </div>
    </div>
</template>
<script>

import axios from "axios";

export default {
    data() {
        return {
            selectedUser: null,
            users: [],
            subscribers: []
        }
    },
    props: [
        'alert'
    ],

    watch: {
        selectedUser(userId) {
            if (!this.subscribers.find((user) => user.id === userId)) {
                this.subscribers.push(this.users.find(user => user.id === userId));
            }
        }
    },

    methods: {
        closeModal() {
            this.$emit('closeSubscribersPopup', this.subscribers);
        },

        deleteSubscriber(deletedSubscriber) {
            this.subscribers = this.subscribers.filter(subscriber => subscriber !== deletedSubscriber);
        },

        async getUsers() {
            const usersResponse = await axios.get('/news-alerts/users');
            this.users = usersResponse.data;
        },
    },

    mounted() {
        this.getUsers();

        if(this.alert.subscribers && this.alert.subscribers.length) {
            this.subscribers = [...this.alert.subscribers];
        }
    },
}
</script>

