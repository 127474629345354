<!--suppress XmlInvalidId -->
<template>
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div id="approval_request_dashboard">
						<div class="container">
							<div class="row">
								<div class="col-12 d-lg-flex justify-content-between border-1 mb-4 mt-2">
									<h3 class="title mb-3">Products</h3>
									<div>
											<a class="btn btn-white new_request_btn" href="/dashboard/product/order" style="line-height:25px;">
												Orders
											</a>

											<a v-if="user_permissions.includes('operations.product.create')" class="btn btn-white" href="/dashboard/product/create" style="line-height: 26px;">
												New Product
											</a>
									</div>
								</div>
							</div>
						</div>

						<div class="container">
							<div class="row">
								<div class="col-12 d-lg-flex justify-content-between">
									<div class="form-group pr-2 mt-5 mt-md-0 mt-lg-0" style="width: 50%">
										<label class="font-weight-500 text-dark-grey " for="keywords">Keywords</label>
										<input id="keywords" v-model="filters.keywords" class="form-control border-none pl-0" name="keywords" placeholder="Title or description..." type="text">
										<span>{{ errors.first('keywords') }}</span>
									</div>
									<!--<div class="form-group pr-2">-->
									<!--    <label for="type_filter" class="form-label font-weight-500 text-dark-grey">Type</label>-->
									<!--    <select name="type_filter" id="type_filter" v-model="filters.type_id" class="form-control border-none pl-0">-->
									<!--        <option v-for="type in filter_type_options" v-bind:value="type.id" v-html="type.title" :selected="type.id == filters.type_id"></option>-->
									<!--    </select>-->
									<!--</div>-->
									<!--<div class="form-group pr-2">-->
									<!--    <label for="stock_filter" class="form-label font-weight-500 text-dark-grey">Stock</label>-->
									<!--    <select name="stock_filter" id="stock_filter" v-model="filters.stock_id" class="form-control border-none pl-0">-->
									<!--        <option v-for="stock in filter_stock_options" v-bind:value="stock.id" v-html="stock.title" :selected="stock.id == filters.stock_id"></option>-->
									<!--    </select>-->
									<!--</div>-->
								</div>
							</div>
						</div>

						<div class="container">
							<div class="row">
								<div class="col-12">
									<ul class="list-group">N
										<li class="list-group-item cursor-default d-none d-md-none d-lg-block">

											<div class="container">

												<div class="row">
													<div class="col-5">
														<span class="font-weight-500 text-dark-grey">Name</span>
													</div>
													<div class="col-2">
														<span class="font-weight-500 text-dark-grey">Weight (Kg)</span>
													</div>
													<div class="col-2">
														<span class="font-weight-500 text-dark-grey">Volume (ml)</span>
													</div>
													<div class="col-3 text-center">
														<span class="font-weight-500 text-dark-grey">In Stock | Shipped</span>
													</div>
												</div>
											</div>
										</li>

										<li v-for="(item, itemKey) in items" class="list-group-item cursor-default">
											<div class="container">
												<div class="row pt-2 pb-2">

													<div class="col-5">
														<a :href="'/dashboard/product/' + item.identifier">
															<span class="font-weight-500 white-space-nowrap">{{
																	item.name || 'Not set'
																}}</span> </a> <br>
														<span class="small">SKU: {{ item.sku }}</span> <br>
														<span class="small">{{ item.description }}</span>
													</div>

													<div class="col-lg-2">
                                                        <span class="text-light-grey white-space-nowrap">
															{{ number_en(item.weight) }}
                                                        </span>
													</div>

													<div class="col-lg-2">
                                                        <span class="text-light-grey white-space-nowrap">
															{{ number_en(item.volume || 'n.a.') }}
                                                        </span>
													</div>

													<div class="col-lg-3 text-center">
                                                        <span class="font-weight-500 white-space-nowrap">
                                                            <!-- Used to be stock -->
                                                            {{ number_en(item.total_in_stock, 0) }} | {{ number_en(item.total_shipped, 0) }}
                                                        </span>
													</div>

												</div>
											</div>
										</li>
									</ul>
								</div>

								<div v-if="uicontrol.show_load_more" class="col-12 text-center">

									<button class="btn btn-sm btn-outline-secondary mt-4 btn-rounded loadmore" @click="loadMore()">
										Load more...
									</button>

								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>

import {DateTime} from "luxon";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Swal from "sweetalert2";
import {number_en} from '@/misc/number_utilities';

export default {
	name: "ProductsDashboard",
	data: function () {
		return {

			is_admin: is_admin,

			filters: {
				keywords: '',
				type_id: null,
				stock_id: null,
				urgency_id: null,
				date_range_start: null,
				date_range_end: null,
				limit: 15
			},

			available_type_options: [
				{
					title: 'Product',
					id: 'product',
					permission_prerequisite: null
				}
			],

			available_stock_options: [
				{
					title: 'In stock',
					id: 'in_stock'
				},
				{
					title: 'Out of stock',
					id: 'out_of_stock'
				}
			],

			any_option: {
				title: 'Any',
				id: null
			},

			flatpickr_config: {
				dateFormat: "Y-m-d H:i:ss",
				altInput: true,
				altFormat: "F j, Y"
			},

			items: [],

			uicontrol: {
				show_load_more: false
			},
			number_en

		}

	},

	props: [
		'user_permissions'
	],

	computed: {

		filter_stock_options() {
			let options = this.available_stock_options.slice(0);
			options.unshift(this.any_option);
			return options;
		},

		filter_type_options() {
			let options = this.available_type_options.slice(0);
			options.unshift(this.any_option);
			return options;
		},

		filter_urgency_options() {
			let options = this.available_urgency_options.slice(0);
			options.unshift(this.any_option);
			return options;
		},

		params() {

			let params = Object.assign({}, this.filters)

			if (params.keywords == '')
				delete params.keywords;

			// Add offset
			params.offset = this.items.length ? this.items.length + 1 : 0;

			return params

		}


	},

	watch: {

		filters: {
			deep: true,
			handler: _.debounce(function () {

					this.items = [];
					this.get().then(response => {
						this.items = response.data.results;
						this.uicontrol.show_load_more = response.data.more_results > 0;
					});
				},

				500 // debounce time

			)
		}

	},

	components: {
		flatPickr
	},

	mounted() {

		this.get().then(response => {
			this.items = response.data.results;
			this.uicontrol.show_load_more = response.data.more_results > 0;
		});

	},

	methods: {

		deleteItem(item, itemKey) {


			Swal.fire({
				title: 'Delete?',
				text: "This is not reversible!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {

				if (result.isConfirmed) {

					axios.delete('/dashboard/approval-requests/delete/' + item.identifier).then(response => {
						this.items.splice(itemKey, 1);
					});

				}
			})


		},

		get: function () {
			return axios.get('/dashboard/product/get', {
				params: this.params
			});
		},

		loadMore() {
			this.get().then(response => {
				this.items = this.items.concat(response.data.results);
				this.uicontrol.show_load_more = response.data.more_results > 0;
			});
		},

		formatTimeStamp(timestamp) {
			return DateTime.fromISO(timestamp).toFormat('d LLL yyyy H:mm');
		}

	},
}
</script>

<style scoped>

</style>
