 <template>
    <div>
        <div class="container" v-if="uicontrol.ready">


            <div class="row">

                <div class="col-lg-3 d-flex pt-4 mb-4">

                    <h3 class="title">NDA Request</h3>

                    <div class="status" style="margin-top: 3px;">

                        <span class="ml-2 font-weight-500 text-primary" v-if="approval_request.status == 'active'">
                            Active
                        </span>

                        <span class="ml-2 font-weight-500 text-success" v-if="approval_request.status == 'approved'">
                            Approved
                        </span>

                        <span class="ml-2 font-weight-500 text-danger" v-if="approval_request.status == 'rejected'">
                            Rejected
                        </span>

                        <span class="ml-2 font-weight-500 text-secondary" v-if="approval_request.status == 'completed'">
                            Completed
                        </span>

                        <span class="ml-2 font-weight-500 text-secondary" v-if="approval_request.status == 'closed'">
                            Closed
                        </span>

                    </div>

                </div>

                <div class="col-lg-4 mb-4">
                    <div class="pb-2 d-flex justify-content-end">

                        <div class="text-right mr-2 mt-2" >
                            <h4 class="mb-0 text-grey">Subscribers</h4>
                            <p class="small">Will get notified of the NDA request progress</p>
                        </div>

                        <user_permissions
                            :users="subscribers"
                            :permission_name="'approval_request.subscriber.' + approval_request.id"
                            :permission_prerequisite="'operations.approval_request.nda.view'"
                            :editable="editable && is_author"
                            @open_search="$validator.validate()">
                        </user_permissions>
                    </div>
                </div>

                <div class="col-lg-5 mb-4">
                    <div class="pb-2 d-flex justify-content-end">
                        <div class="text-right mr-2 mt-2" >
                            <h4 class="mb-0 text-grey">Participant</h4>
                            <template v-if="approval_request.status == 'active'">
                                <p class="small text-grey mb-0" v-if="is_author && !participants.length">You need a participant to approve your request</p>
                                <p class="small text-grey mb-0" v-if="is_participant">You can approve or reject the request</p>
                            </template>
                        </div>

                        <user_permissions
                            :users="participants"
                            :permission_name="'approval_request.participate.' + approval_request.id"
                            :permission_prerequisite="'operations.approval_request.nda.view'"
                            @added_user="popupAddedUser($event)"
                            :editable="editable && is_author && recipient_valid"
                            @open_search="$validator.validate()">
                        </user_permissions>
                    </div>

                    <!-- Statuses-->
                    <div class="d-flex justify-content-end">

                        <!-- Author -->
                        <div v-if="is_author">
                            <button class="btn btn-rounded btn-success" @click="completeRequest($event)" v-if="approval_request.status == 'approved'">
                                Mark Complete
                            </button>
                            <button class="btn btn-rounded btn-warning" @click="reopenRequest($event)" v-if="approval_request.status == 'rejected'">
                                Reopen request
                            </button>
                            <button class="btn btn-rounded btn-danger" @click="closeRequest($event)" v-if="['rejected', 'approved'].includes(approval_request.status)">
                                Close request
                            </button>
                            <button class="btn btn-rounded btn-white" @click="save($event)" v-if="['active'].includes(approval_request.status) && editable" :class="{ loading : uicontrol.saving }">
                                <div data-loader="circle-side"></div>
                                Save
                            </button>
                        </div>

                        <!-- Participant -->
                        <div v-if="is_participant">
                            <template v-if="['active'].includes(approval_request.status)">
                                <button class="btn btn-rounded btn-outline-success" @click="approveRequest($event)" :disabled="!recipient_valid">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                                    </svg>
                                    Approve
                                </button>
                                <button class="btn btn-rounded btn-outline-danger" @click="rejectRequest($event)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                    Reject
                                </button>
                                <p class="small text-danger" v-if="!recipient_valid">The recipient fields are not valid.</p>
                            </template>
                        </div>
                    </div>

                </div>


            </div>
            <div class="row g-5">
                <div class="col-12">
                    <form class="needs-validation" novalidate>
                        <div class="row g-3">
                            <div class="col-sm-6">
                                <div class="d-flex" style="width: 100%">
                                    <div class="form-group mr-2" style="width: 100%">
                                        <label for="title" class="form-label font-weight-500">Title</label>
                                        <input type="text" class="form-control" id="title" name="title" placeholder="" v-validate="'required'" v-model="approval_request.title" :disabled="!is_author || !editable">
                                        <span class="text-danger">{{ errors.first('title') }}</span>
                                    </div>
                                    <div class="form-group">
                                        <label for="currency" class="form-label font-weight-500" style="min-width: 100px">Urgency</label>
                                        <select name="field" id="currency" v-model="approval_request.urgency" class="form-control" :disabled="!is_author || !editable">
                                            <option v-for="urgency in available_urgency_options" v-bind:value="urgency.value" v-html="urgency.label" :selected="urgency.value == urgency.urgency"></option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group mt-2 file-upload">
                                    <h5>Signed nda file (optional)</h5>
                                    <p class="small" v-if="!has_signed_nda_file">
                                        In order to skip the HelloSign flow and use a custom signed file, please upload a the signed nda file here
                                    </p>
                                    <p class="small text-danger p-2 bg-warning" style="border-radius: 20px;" v-else>

                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle-fill text-danger pl-1 pb-1" viewBox="0 0 16 16">
                                            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                        </svg>

                                        You are using a custom NDA file. This file will be saved upon approval
                                    </p>
                                    <div class="files_wrapper" style="border: 1px dashed rgb(235, 236, 240); border-radius: 6px; padding-top: 137px; padding-bottom: 10px;" :style="{ 'padding-top' : (approval_request.data.files.length) ? '20px' : '137px'}">

                                        <ul class="file_list pl-2 pr-2" v-if="approval_request.data.files.length">
                                            <li v-for="(file, fKey) in approval_request.data.files">
                                                <file :key="fKey" :file="file" :can_delete="editable && is_author" @removed_file="removedFile($event)"></file>
                                            </li>
                                        </ul>

                                        <div id="drop_area" class="files_bg" v-else>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#B4BAC4" class="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"/>
                                                <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
                                            </svg>
                                            <br>
                                            <span>
                                                <span class="bigger font-weight-700">
                                                    Drag &amp; Drop
                                                </span>
                                                files here <br>
                                                or
                                            </span>

                                        </div>

                                        <div class="button-holder text-center" v-if="!approval_request.data.files.length">
                                            <file_upload v-if="editable && is_author" :data="{}" @uploaded="uploadedFile($event)"></file_upload>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">

                                <div>
                                    <label for="info" class="form-label font-weight-500">Request Info</label>
                                    <textarea style="height:60px; resize: none;" rows="2" type="text" class="form-control" name="info" id="info" required v-model="approval_request.data.info" :disabled="!is_author || !editable"></textarea>
                                    <div class="invalid-feedback">
                                        Valid info is required.
                                    </div>
                                </div>

                                <div>
                                    <label for="description" class="form-label font-weight-500">Description</label>
                                    <textarea style="height:150px; resize: none;" rows="2" type="text" class="form-control" name="description" id="description" required v-model="approval_request.description" :disabled="!is_author || !editable"></textarea>
                                    <div class="invalid-feedback">
                                        Valid description is required.
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>


            <div class="row">
                <div class="col-12 mt-2">
                    <h5>Recipient</h5>
                </div>
            </div>
            <div class="row no-gutters" style="width: 100%">

                <div class="col-lg-3 pr-1">
                    <div class="form-group">
                        <input type="text" class="form-control" id="name" name="name" placeholder="Recipient name" v-validate="'required'" v-model="approval_request.data.recipient_name" :disabled="!is_author || !editable">
                        <span class="text-danger">{{ errors.first('name') }}</span>
                    </div>
                </div>

                <div class="col-lg-3 pr-1">
                    <div class="form-group">
                        <input type="text" class="form-control" id="email" name="email" placeholder="Recipient Email" v-validate="'required|email'" v-model="approval_request.data.recipient_email" :disabled="!is_author || !editable">
                        <span class="text-danger">{{ errors.first('email') }}</span>
                    </div>
                </div>

                <div class="col-lg-3 pr-1">
                    <div class="form-group">
                        <input type="text" class="form-control" id="company_name" name="company_name" placeholder="Company name" v-validate="'required'" v-model="approval_request.data.company_name" :disabled="!is_author || !editable">
                        <span class="text-danger">{{ errors.first('company_name') }}</span>
                    </div>
                </div>

                <div class="col-lg-3">
                    <div class="form-group text-right" v-if="uicontrol.nda_templates.ready">
                        <select id="template" name="template" v-model="approval_request.data.selected_nda_template" class="form-control" :disabled="!is_author || !editable" v-validate="'required'">
                            <option v-for="nda_template in available_nda_templates" v-bind:value="nda_template.id" v-html="nda_template.title" :selected="nda_template.id == approval_request.data.selected_nda_template"></option>
                        </select>

                        <span class="text-danger">{{ errors.first('template') }}</span>

                        <a class="btn btn-rounded btn-white ml-2 mt-3" :href="ndaTemplateFileUrl()" target="_blank" v-if="approval_request.data.selected_nda_template">View template</a>

                        <!--<button class="btn btn-sm btn-secondary" v-if="approval_request.data.selected_nda_template" @click="uicontrol.show_pdf_viewer = true">View template</button>-->
                        <!--<div class="modal file-detail-modal show d-block" id="file-detail-modal" tabindex="-1" role="dialog" v-if="uicontrol.show_pdf_viewer">-->
                        <!--    <div class="modal-dialog" role="document">-->
                        <!--        <div class="modal-content">-->
                        <!--            <div class="modal-header">-->
                        <!--                <h5 class="modal-title">Template</h5>-->
                        <!--                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="uicontrol.show_pdf_viewer = false">-->
                        <!--                    <span aria-hidden="true">&times;</span>-->
                        <!--                </button>-->
                        <!--            </div>-->
                        <!--            <div class="modal-body">-->
                        <!--                <object :data="ndaTemplateFileUrl() + '#toolbar=0&navpanes=0&scrollbar=0'" type="application/pdf" style="width: 100%; height: 100%;">-->
                        <!--                    <embed :src="ndaTemplateFileUrl() + '#toolbar=0&navpanes=0&scrollbar=0'" type="application/pdf" />-->
                        <!--                </object>-->
                        <!--            </div>-->
                        <!--        </div>-->
                        <!--    </div>-->
                        <!--</div>-->


                    </div>
                </div>
            </div>

            <div class="row" v-if="approval_request.status == 'completed'">
                <div class="col-12">
                    <a class="btn btn-success btn-lg" :href="signed_file_url">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-download" viewBox="0 0 16 16">
                            <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/>
                            <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/>
                        </svg>
                        Download signed document </a>
                </div>
            </div>

            <div class="row">
                <div class="col-12 mt-4">
                    <h5>Message board</h5>
                </div>
                <div class="col-12">
                    <channel :channel="'approval_request.' + approval_request.identifier" :channel_type="'private'" :commenting_enabled="true" :scroll_bottom="false"></channel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import user_permissions from '@/components/sections/science/UserPermissions';
import file_upload from '@/components/sections/reusable/FileUpload';
import file from "@/components/sections/reusable/File";
import channel from '@/components/chat/Channel';
import Swal from "sweetalert2";
import hellosign_helper from "./hellosign_helper";
import available_currencies from '@/misc/available_currencies';
import droparea from '@/misc/droparea';

export default {
    name: "NDA",
    data: function () {
        return {
            // approval_request.data.selected_nda_template : null,
            uicontrol: {
                saving: false,
                nda_templates: {
                    ready: false
                },
                ready: false,
                show_pdf_viewer : false
            },
            available_currencies: available_currencies,
            available_urgency_options: [
                {
                    label: 'Normal',
                    value: 0
                },
                {
                    label: 'Urgent',
                    value: 1
                },
                {
                    label: 'Critical',
                    value: 2
                },
            ],
            available_nda_templates: [
                {
                    id: null,
                    title: 'Select Template...'
                }
            ],
            signed_file_url: null
        }
    },

    props: [
        'subscribers',
        'participants',
        'approval_request',
        'editable',
        'is_author',
        'is_participant'
    ],

    components: {
        channel,
        file_upload,
        file,
        user_permissions,
    },

    computed : {

        recipient_valid(){
            return Boolean(this.approval_request.data.recipient_name && this.approval_request.data.recipient_email && this.approval_request.data.company_name && this.approval_request.data.selected_nda_template)
        },

        has_signed_nda_file(){
            return Boolean(this.signed_nda_file);
        },

        signed_nda_file(){
            return approval_request.data.files[0];

        }

    },

    mounted() {

        var vm = this;

        // Set this template for the data field
        if (typeof this.approval_request.data.selected_nda_template == 'undefined') {

            this.approval_request.data = {
                selected_nda_template : null,
                recipient_name : '',
                recipient_email : '',
                company_name : '',
                files : []

            }

            this.save();

        }

        // Determine if it has files
        if (['approved', 'completed'].includes(this.approval_request.status)) {


            if(this.has_signed_nda_file){

                // Manual signed nda logic
                this.signed_file_url = window.baseurl + '/file/' + this.signed_nda_file.identifier;

            }else{

                // Hellosign logic

                let signature_request_id = this.approval_request.meta.find(item => {
                    return item.key == 'signature_request_id';
                }).value;

                axios.get('/api/hellosign-webhook/signature-request/' + signature_request_id + '/get-files').then(response => {
                    this.signed_file_url = response.data.file_url;
                });

            }



        }

        hellosign_helper.getTemplates().then(response => {
            this.available_nda_templates.push(...response.data);
            this.uicontrol.nda_templates.ready = true;
        });

        this.uicontrol.ready = true;

        Vue.nextTick(() => {
            droparea.initialize(this, 'emit');
        });

    },

    methods: {

        ndaTemplateFileUrl(){

            var vm = this;

            let template =  vm.available_nda_templates.find(nda_template => {
                return nda_template.id == vm.approval_request.data.selected_nda_template;
            });

            let url = template.template_file;

            return url;
        },

        popupAddedUser(user){

            Swal.fire('Participant added', `Your request has been sent to ${user.name}. Once this NDA request has been approved, your specified recipient will receive the NDA via email.`, 'info');

        },

        deleteItem(iKey) {
            this.approval_request.data.items.splice(iKey, 1);
            this.update();
        },

        update() {
            this.$emit('update', this.approval_request);
            return this;
        },

        save() {

            var vm = this;

            vm.$validator.validate().then(valid => {

                if (valid) {

                    // Fake saving
                    vm.uicontrol.saving = true;
                    setTimeout(function () {
                        vm.uicontrol.saving = false;
                    }, 500);

                    vm.$emit('save', vm.approval_request);

                    return vm;

                }

            });


        },

        approveRequest() {

            var vm = this;
            this.approval_request.status = 'approved';
            this.save();

        },

        rejectRequest() {
            this.approval_request.status = 'rejected';
            this.save();
        },

        completeRequest() {
            this.approval_request.status = 'completed';
            this.$emit('set_editable', false);
            this.save();
        },

        closeRequest() {
            this.approval_request.status = 'closed';
            this.$emit('set_editable', false);
            this.save();
        },

        reopenRequest() {
            this.approval_request.status = 'active';
            this.$emit('set_editable', true);
            this.save();
        },

        uploadedFile(file) {
            this.approval_request.data.files.push(file);
            this.save();
        },

        removedFile(identifier) {
            let file_index = this.approval_request.data.files.findIndex(el => {
                return el.identifier == identifier;
            })
            this.approval_request.data.files.splice(file_index, 1);
            this.save();
        }

    }

}
</script>


<style scoped>

.items-columns-container {
    display: inline-grid;
    grid-template-columns: 30fr 30fr 15fr 15fr 20fr 20fr 20fr auto;
}

.btn-white {
    background-color: #FFFFFF;
    border: 1px solid #DFE1E6;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px -1px rgba(13, 42, 64, 0.06);
    border-radius: 100px;
    color: #44526C;
    margin-left: 20px;
    outline: none;
    font-size: 14.4px;

    &:hover {
        background-color: #f7f7f7;
    }

}


.file-detail-modal {

    text-align: center;

	.modal-dialog {

		width: 90%;
		max-width: initial;

		.modal-content {
			min-height: 90vh;

			.file-detail-modal-view {
				width: 100%;
				height: 90vh;
			}

		}

	}

	.modal-body {
		height: 100% !important;
	}
}

#pdf-container {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}


@media print {
    .file-detail-modal {
        display: none !important;

    }
}


</style>
