<!--suppress XmlInvalidId -->
<template>
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div id="approval_request_dashboard">
						<div class="container">
							<div class="row">
								<div class="col-12 d-lg-flex justify-content-between border-1 mb-4 mt-2">
									<h3 class="title mb-3">Expenses</h3>
									<div>
										<template v-if="user_permissions.includes('operations.expenses.create')">
											<a class="btn btn-white mr-2" style="line-height: 26px;" href="/dashboard/expense/create">
												New Expense </a>
										</template>
										<button class="btn btn-white" style="line-height: 26px;" @click="exportCsv($event)">
											Export CSV
										</button>
									</div>


								</div>
							</div>
						</div>

						<div class="container" name="filters">

							<div class="row">


								<div class="col-12 d-lg-flex justify-content-between">

									<div class="form-group pr-2 mt-5 mt-md-0 mt-lg-0" style="width: 50%">
										<label class="font-weight-500 text-dark-grey " for="keywords">Keywords</label>
										<input type="text" id="keywords" name="keywords" class="form-control border-none pl-0" v-model="filters.keywords" placeholder="Title or description...">
										<span>{{ errors.first('keywords') }}</span>
									</div>

									<div class="form-group date_range_filter" style="display: block; min-width: 300px">
										<label class="font-weight-500 text-dark-grey " for="keywords">Date range</label>
										<div class="pickers">
											<img class="mini-calendar" src="/assets/icons/mini-calendar.svg">
											<flat-pickr v-model="filters.start_date" placeholder="Start date"></flat-pickr>
											<span></span>
											<flat-pickr v-model="filters.end_date" placeholder="End date"></flat-pickr>
										</div>
									</div>

									<div class="form-group pr-2">
										<label for="type_filter" class="form-label font-weight-500 text-dark-grey">Type</label>
										<select name="type_filter" id="type_filter" v-model="filters.type_id" class="form-control border-none pl-0">
											<option v-for="type in filter_type_options" v-bind:value="type.id" v-html="type.title" :selected="type.id == filters.type_id"></option>
										</select>
									</div>

								</div>
							</div>
						</div>

						<div class="container" name="items table">
							<div class="row">
								<div class="col-12">
									<ul class="list-group">
										<li class="list-group-item cursor-default d-none d-md-none d-lg-block">
											<div class="container">
												<div class="row">

													<div class="col-2">
														<span class="font-weight-500 text-dark-grey">Category</span>
													</div>

													<div class="col-3">
														<span class="font-weight-500 text-dark-grey">Description</span>
													</div>

													<div class="col-2">
														<span class="font-weight-500 text-dark-grey">Amount</span>
													</div>

													<div class="col-2">
														<span class="font-weight-500 text-dark-grey">Author</span>
													</div>

													<div class="col-2 text-center">
														<span class="font-weight-500 text-dark-grey">Date</span>
													</div>

													<div class="col-1 text-right">
														<span class="font-weight-500 text-dark-grey">Action</span>
													</div>

												</div>
											</div>
										</li>

										<li class="list-group-item" v-for="(item, itemKey) in items" v-if="items.length">

											<!--cursor-pointer expense_item-->

											<div class="container">

												<div class="row pt-2 pb-2">

														<div class="col-2">
															<a :href="window.location.href + '/' + item.identifier">
																<span class="font-weight-500 white-space-nowrap">{{
																		item.category
																	}}</span>
															</a>
														</div>

														<div class="col-lg-3 overflow-hidden">
																<span class="text-light-grey white-space-nowrap">
																	{{ item.description }}
																</span>
														</div>

														<div class="col-lg-2">
															<span class="text-light-grey white-space-nowrap">
																<currency_sign :currency="item.currency" v-if="item.amount"></currency_sign>{{
																	number_en(item.amount) || 'n.a.'
																}}
															</span>
														</div>

														<div class="col-lg-2">
															<span class="font-weight-500 white-space-nowrap">
																{{
																	Boolean(item.author) ? item.author.name : 'No author'
																}}
															</span>
														</div>

														<div class="col-lg-2 text-right">
															<span class="font-weight-500 white-space-nowrap">
																{{ formatTimeStamp(item.created_at) }}
															</span>
														</div>



													<div class="col-1 text-right">
														<i class="fa fa-trash btn-icon cursor-pointer text-danger" @click="deleteItem(item, itemKey)" v-if="item.source_id == null && is_admin"></i>
													</div>

												</div>

											</div>


										</li>
									</ul>
								</div>

								<div class="col-12 text-center" v-if="uicontrol.show_load_more">
									<button @click="loadMore()" class="btn btn-sm btn-outline-secondary mt-4 btn-rounded loadmore">
										Load more...
									</button>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

import {formatTimeStamp} from '@/misc/date_utilities';

import {number_en} from '@/misc/number_utilities';
import currency_sign from '../reusable/CurrencySign';
import {DateTime} from "luxon";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Swal from "sweetalert2";
import available_currencies from '@/misc/available_currencies';

export default {
	name: "ExpensesDashboard",

	data: function () {

		return {

			is_admin: is_admin,

			filters: {
				type_id: null,
				status_id: null,
				start_date: null,
				end_date: null,
				limit: 15
			},

			available_type_options: [
				{
					title: 'Product Order',
					id: 'product_order',
					permission_prerequisite: null
				},
				{
					title: 'Shipment',
					id: 'shipment',
					permission_prerequisite: null
				},
				{
					title: 'Miscellaneous',
					id: 'misc',
					permission_prerequisite: null
				}
			],

			available_status_options: [
				{
					title: 'Paid',
					id: 'paid'
				}
			],

			any_option: {
				title: 'Any',
				id: null
			},

			flatpickr_config: {
				dateFormat: "Y-m-d H:i:ss",
				altInput: true,
				altFormat: "F j, Y"
			},

			items: [],

			uicontrol: {
				show_load_more: false
			},

			number_en,
			window,
			formatTimeStamp

		}

	},

	props: [
		'user_permissions'
	],


	computed: {

		filter_type_options() {
			let options = this.available_type_options.slice(0);
			options.unshift(this.any_option);
			return options;
		},

		params() {

			let params = Object.assign({}, this.filters)

			if (params.keywords == '')
				delete params.keywords;

			// Add offset
			params.offset = this.items.length ? this.items.length + 1 : 0;

			return params

		}

	},

	watch: {

		filters: {
			deep: true,
			handler: _.debounce(function () {
					this.items = [];
					this.get().then(response => {
						this.items = response.data.results;
						this.uicontrol.show_load_more = response.data.more_results > 0;
					});
				},
				500 // debounce time
			)
		}

	},

	components: {
		flatPickr,
		currency_sign
	},

	mounted() {

		this.get().then(response => {
			this.items = response.data.results;
			this.uicontrol.show_load_more = response.data.more_results > 0;
		});

	},

	methods: {

		exportCsv(event) {

			event.preventDefault();

			var vm = this;

			let csvFileData = this.items.map(function (item) {

				let sign = available_currencies.find(currency => {
					return currency.value == item.currency
				}).code;

				console.log(sign);

				return [
					item.category,
					item.description.replaceAll(',', ''),
					item.amount,
					sign,
					item.status,
					item?.data.files.length ? 'Y' : 'N',
					vm.formatTimeStamp(item.created_at)
				]
			})

			//define the heading for each row of the data
			var csv = 'Category,Description,Amount,Currency,Status,Receipt Attached,Date\n';

			//merge the data with CSV
			csvFileData.forEach(function (row) {
				csv += row.join(',');
				csv += "\n";
			});

			var hiddenElement = document.createElement('a');
			hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
			hiddenElement.target = '_blank';

			//provide the name for the CSV file to be downloaded
			hiddenElement.download = 'expenses-report-' + Date().toLocaleLowerCase().slice(4, 21).replaceAll(' ', '-') + '.csv';
			hiddenElement.click();

		},

		col() {
			return this.is_admin ? 'col-lg-5' : 'col-lg-6';
		},

		deleteItem(item, itemKey) {

			Swal.fire({
				title: 'Delete?',
				text: "This is not reversible!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.isConfirmed) {
					axios.delete('/dashboard/expense/delete/' + item.identifier).then(response => {
						this.items.splice(itemKey, 1);
					});
				}
			})

		},

		get: function () {

			return axios.get('/dashboard/expense/get', {
				params: this.params
			});

		},

		loadMore() {
			this.get().then(response => {
				this.items = this.items.concat(response.data.results);
				this.uicontrol.show_load_more = response.data.more_results > 0;
			});
		}

	},
}
</script>


<style scoped>

.expense_item:hover {
	background-color: #f7f7f7 !important;
	padding-left: 20px !important;
}

.date_range_filter .pickers, .date_range_filter label{
	width: 100%;
}

.pickers{
	display: inline-flex;
}

.date_range_filter span {
	margin-right: 18px;
	margin-top: 11px;
}

</style>
