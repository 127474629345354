<template>
	<div class="container reports">
		<div class="experiment-top-nav">
			<h4>Result Budgeting</h4>

			<div class="tools">

				<div class="add-tags">
					<h4>Tags: </h4>
					<div class="added">
						<div class="tag" v-for="(selectedTag, stKey) in filters.tags">
							<p v-text="selectedTag.name"></p>
							<img src="/assets/icons/remove-lab.svg" class="remove-lab" @click="toggleTag(selectedTag)">
						</div>
					</div>
					<div class="add" id="add-tag">
						<div class="tag" @click="uicontrol.select_tag = !uicontrol.select_tag">
							<p>Select</p>
							<img class="add-icon add-tag" src="/assets/icons/lab-add.svg">
							<img class="add-active-icon add-tag" :src="uicontrol.select_tag ? '/assets/icons/add-lab-up.svg' : '/assets/icons/add-lab-down.svg'">
						</div>
						<div class="select-tag" id="select-tag" v-if="uicontrol.select_tag">
							<form action="">
								<div class="form-check" v-for="(tag, tKey) in tags">
									<input class="form-check-input" type="checkbox" value=""
										:id="tag.id"
										:checked="filters.tags.find(item => item.id == tag.id)"
										@click="toggleTag(tag)">
									<label class="form-check-label" :for="tag.id" v-text="tag.name"></label>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div class="add-labs">

					<h4>Labs: </h4>
					<div class="added">
						<div class="lab" v-for="(selectedLab, slKey) in filters.labs">
							<p v-text="selectedLab.name"></p>
							<img src="/assets/icons/remove-lab.svg" class="remove-lab" @click="toggleLab(selectedLab)">
						</div>
					</div>

					<div class="add" id="add-lab">
						<div class="lab" @click="uicontrol.select_lab = !uicontrol.select_lab">
							<p>Select</p>
							<img class="add-icon add-lab" src="/assets/icons/lab-add.svg">
							<img class="add-active-icon add-lab" :src="uicontrol.select_lab ? '/assets/icons/add-lab-up.svg' : '/assets/icons/add-lab-down.svg'">
						</div>
						<div class="select-lab" id="select-lab" v-if="uicontrol.select_lab">
							<form action="">
								<div class="form-check" v-for="(lab, lKey) in labs">
									<input class="form-check-input" type="checkbox" value=""
										:id="lab.id"
										:checked="filters.labs.find(item => item.id == lab.id)"
										@click="toggleLab(lab)">
									<label class="form-check-label" :for="lab.id" v-text="lab.name"></label>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div class="date_range_filter">
					<img class="mini-calendar" src="/assets/icons/mini-calendar.svg">
					<flat-pickr v-model="filters.start_date" placeholder="Start date"></flat-pickr>
					<span></span>
					<flat-pickr v-model="filters.end_date" placeholder="End date"></flat-pickr>
				</div>

				<div class="controls d-flex">
					<button class="btn mr-1" @click="timelineMove('left')">&laquo;</button>
					<button class="btn mr-1" @click="timelineMove('zoomout')">-</button>
					<button class="btn" @click="timelineMove('zoomin')">+</button>
					<button class="btn ml-1" @click="timelineMove('right')">&raquo;</button>
				</div>

			</div>

			<div class="d-inline" v-if="cross_link">
				<a class="btn btn-white" :href="cross_link.url">{{cross_link.text}}</a>
			</div>

		</div>
		<div class="row">
			<div class="col-12">
				<div class="reports-table">
					<div class="t-header">
						<p>Section</p>
						<p>M4Q Chapter</p>
						<p>Title</p>
						<p>Description</p>
						<p>Responsability</p>
						<p>Total Cost</p>
						<div class="mini">
							<p class="mr-2">Timeline</p>
							<div class="mini-group">
								<div class="legend-item p-1 bg-danger">
									<p class="text-white">Requested</p>
								</div>
								<div class="legend-item p-1 bg-warning">
									<p class="text-white">Approved</p>
								</div>
								<div class="legend-item p-1 bg-success">
									<p class="text-white">Paid</p>
								</div>
							</div>
						</div>
						<p>exper.</p>
					</div>
					<div class="t-body" v-if="reports.length">
						<report
							v-for="(report, rkey) in reports"
							:report="report"
							:months="months"
							:category="'experiments'"
							:timeline="'purchase'"
							:key="rkey">
						</report>
						<div class="t-row bottom">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div class="mo">
								<div v-if="months.length <= 18 && mKey < months.length - 1" v-for="(month, mKey) in months">
									<span v-text="(mKey <  months.length - 1) ? month.toFormat('MMM y') : ' '"></span>
								</div>
							</div>
							<div></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {DateTime} from "luxon";

// Result
import report from "./reports/ManufacturingStudyResultReport";

export default {
	name: "ExperimentResultPurchaseReports",

	data: function () {
		return {
			DateTime: DateTime,
			filters: {
				start_date: null,
				end_date: null,
				labs : [],
				tags : []
			},
			reports : [],
			uicontrol : {
				select_lab : false,
				select_tag : false
			}
		}
	},

	components: {
		flatPickr,
		report
	},

	props : [
		'labs', 'tags', 'cross_link'
	],

	computed : {

		months(){

			let start_month = DateTime.fromSQL(this.filters.start_date);
			let end_month = DateTime.fromSQL(this.filters.end_date);

			// Populate array with start, end months and every month in between
			let months = [];
			var increment = 0;

			var current_month = start_month;

			while(current_month < end_month){
				current_month = start_month.plus({ months: increment });
				months.push(current_month);
				increment++;
			}

			return months;

		}

	},

	watch: {
		filters: {
			handler(val) {
				this.get();
			},
			deep: true
		}
	},

	mounted() {

		var vm = this;

		this.filters.start_date = DateTime.local().minus({'months' : 4}).startOf('month').toSQL();
		this.filters.end_date = DateTime.local().plus({'months' : 3}).endOf('month').toSQL();

		// Capture click outside the component
		window.addEventListener('click', function(e){
			if (vm.uicontrol.select_lab && (!document.getElementById('select-lab').contains(e.target) && !document.getElementById('add-lab').contains(e.target))){
				vm.uicontrol.select_lab = false;
			}
		});

		// Capture click outside the component
		window.addEventListener('click', function(e){
			if (vm.uicontrol.select_tag && (!document.getElementById('select-tag').contains(e.target) && !document.getElementById('add-tag').contains(e.target))){
				vm.uicontrol.select_tag = false;
			}
		});

	},

	methods: {

		timelineMove(direction){

			let start_date = DateTime.fromSQL(this.filters.start_date);
			let end_date = DateTime.fromSQL(this.filters.end_date);

			switch(direction){

				case 'left':

					start_date = start_date.minus({ months : 1 });
					end_date = end_date.minus({ months : 1 });

					break;

				case 'right':

					start_date = start_date.plus({ months : 1 });
					end_date = end_date.plus({ months : 1 });

					break;

				case 'zoomin':

					start_date = start_date.plus({ months : 1 });
					end_date = end_date.minus({ months : 1 });

					break;

				case 'zoomout':

					start_date = start_date.minus({ months : 1 });
					end_date = end_date.plus({ months : 1 });

					break;

			}

			this.filters.start_date = start_date.toSQL();
			this.filters.end_date = end_date.toSQL();

		},

		get() {

			var vm = this;

			this.reports = [];

			return axios.get('/dashboard/science/experiments/results/get', {
				params: {
					from: vm.filters.start_date,
					to: vm.filters.end_date,
					labs : this.filters.labs.map(lab => lab.id),
					tags : this.filters.tags.map(tag => tag.id),

				}
			}).then(r => {
				this.reports = r.data;
			});

		},

		toggleLab(lab){

			let index = this.filters.labs.findIndex(item => item.id == lab.id);

			console.log('Index', index);

			if(index > -1){
				this.filters.labs.splice(index, 1);
			}else{
				this.filters.labs.push(lab);
			}

		},

		toggleTag(tag){

			let index = this.filters.tags.findIndex(item => item.id == tag.id);

			console.log('Index', index);

			if(index > -1){
				this.filters.tags.splice(index, 1);
			}else{
				this.filters.tags.push(tag);
			}

		},

	}
}
</script>

<style scoped>

</style>

