<template>
	<div class="fullwidth mb-4">

		<h4>{{title}}</h4>
		<p class="small text-grey">New cases smoothed per million</p>

		<div class="boxes d-inline-flex fullwidth justify-content-between" v-if="uicontrol.ready">

			<div v-for="(step, stepKey) in item.new_cases_smoothed_per_million" v-if="stepKey > 0" :style="{ 'width' : 100 / item.new_cases_smoothed_per_million.length + '%' }" class="text-center">
				<div class="square text-center"
					data-toggle="tooltip" data-placement="top"
					:title="(item.new_cases_smoothed_per_million.length - stepKey - 1) + ' weeks ago: ' + percentageDifference(step, item.new_cases_smoothed_per_million[stepKey - 1])"
					:class="{ 'background-success-important' : ( step < item.new_cases_smoothed_per_million[stepKey - 1] )  }">
				<span>
					{{step}}
				</span>
				</div>
				<span class="smaller">{{ percentageDifference(step, item.new_cases_smoothed_per_million[stepKey - 1]) }}</span>
			</div>



		</div>

		<template v-if="!uicontrol.ready">
			<div class="fullwidth text-center">
				<div class="spinner-border" role="status" v-if="uicontrol.loading">
					<span class="sr-only">Loading...</span>
				</div>
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pause" viewBox="0 0 16 16" v-else="" style="width: 35px; height: 35px;">
					<path d="M6 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5zm4 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5z"/>
				</svg>
			</div>
		</template>

	</div>

</template>

<script>

var approx = require('approximate-number');
const retry = require('retry');
const humanNumber = require('human-number');

const operation = retry.operation({
	retries: 10,
	factor: 3,
	minTimeout: 1 * 1000,
	maxTimeout: 5 * 1000,
	randomize: true,
});


export default {
	name: "CovidStatisticsVaccinationsVsInfectionsAveraged",
	data: function () {
		return {
			humanNumber: humanNumber,
			item: [],
			uicontrol: {
				ready: false,
				loading: false
			},
			approx: approx
		}
	},

	props: [
		'countries', 'title', 'sort_by', 'sort_order', 'steps_back', 'step_size', 'index'
	],

	mounted() {

		var vm = this;

		if (this.index == 0) {
			this.getData();
		} else {
			this.$root.$on('load_index_' + this.index, () => {
				this.getData();
			});
		}


	},

	methods: {

		getData() {

			var vm = this;

			vm.uicontrol.loading = true;

			operation.attempt(async (currentAttempt) => {

				console.log('sending request: ', currentAttempt, ' attempt');

				try {

					await axios.get('https://raphstats.xyz/api/data/infections-vs-vaccinations-averaged', {
						params: {
							countries: vm.countries,
							sort_by: vm.sort_by,
							sort_order: vm.sort_order,
							steps_back : vm.steps_back,
							step_size : vm.step_size
						}
					}).then(r => {

						if (r.status == 404)
							throw('Error, please retry');
						vm.item = r.data;
						vm.uicontrol.ready = true;

						vm.$root.$emit('load_index_' + (vm.index + 1));

						setTimeout(function () {
							$('[data-toggle="tooltip"]').tooltip();
						}, 500);

					});

				} catch (e) {
					if (operation.retry(e)) {
						return;
					}
				}
			});

		},

		percentageDifference(new_val, old_val) {

			if (old_val == 0)
				return 'n.a.';

			return (new_val < old_val ? '' : '+') + ((new_val - old_val) / old_val * 100).toFixed(1) + '%';

		}
	}


}
</script>

<style scoped>

</style>