<template>

    <div>

        <div class="ep" action="begin"></div>

        <div class="form-group">

            <!-- If no file and not uploading -->
            <template v-if="!uicontrol.uploading">
                <a class="btn btn-edit-exp ml-0" style="padding: 10px 19px" @click="file_input.click()">
                  <span class="text-primary font-weight-500">
                    Browse files
                  </span>
                </a>
            </template>

            <!-- If uploading -->
            <div v-else-if="uicontrol.uploading">
                <div class="progress">
                    <div class="progress-bar bg-success" role="progressbar" v-bind:style="{ 'width' : (uicontrol.upload_progress + '%') }" :aria-valuenow="uicontrol.upload_progress" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <h2 class="text-center" v-text="uicontrol.upload_progress + '%'"></h2>
            </div>

            <input type="file" class="d-none" :name="'file_input_' + id" :id="'file_input_' + id" accept="image/*,application/*" v-on:change="filePicked($event)">

        </div>

    </div>

</template>

<script>

export default {

    name: "FileUpload",
    props: ["category"],
    data : function(){
        return {
            id : '',
            uicontrol: {
                uploading: false,
                upload_progress: 0
            },
            selected_file: null,
            file_input: null,
            window : window
        }
    },

    computed: {

    },

    created(){
        this.id = this.makeid(10);
        this.$parent.$on('drop_file', this.fileDropped);
    },

    mounted() {
        this.file_input = document.getElementById('file_input_' + this.id);
    },

    methods : {

        makeid(length) {
            var result           = [];
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result.push(characters.charAt(Math.floor(Math.random() *
                    charactersLength)));
            }
            return result.join('');
        },

        filePicked: function (event) {

            this.selected_file = event.target.files[0];

            // Upload
            this.upload();

        },

        fileDropped: function(file){

			console.log('File dropped');

            this.selected_file = file;
            this.upload();

        },

        upload: function (event) {

            let vm = this;

            vm.uicontrol.uploading = true;

            let form_data = new FormData();

            form_data.append('uploaded_file', this.selected_file);

            axios.post('/file', form_data, {

                headers: {
                    'Content-Type': 'multipart/form-data'
                },

                onUploadProgress: function (uploadEvent) {
                    vm.uicontrol.upload_progress = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
                }

            }).then(response => {

                vm.uicontrol.uploading = false;
                vm.$emit('uploaded', response.data.file);

            })

        },

    }

}
</script>

<style scoped>

</style>
