<template>

    <main class="mb-4">

        <channel
            :channel="messaging_channel"
            :channel_type="'private'"
            :commenting_enabled="can_participate"
            :scroll_bottom="false"
            >
        </channel>

    </main>

</template>

<script>

    import channel from '@/components/chat/Channel';

    export default {
        name: "MessagingChannel",

        components : {
            channel
        },

        props : [
            'result_identifier',
            'tab',
            'field',
            'val',
            'can_participate',
            'result_parent',
            'messaging_channel'
        ],

        computed : {

        }
    }

</script>

<style scoped>

</style>
