<!--suppress XmlInvalidId -->
<template>
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div id="approval_request_dashboard">
						<div class="container">
							<div class="row">
								<div class="col-12 d-lg-flex justify-content-between border-1 mb-4 mt-2">
									<h3 class="title mb-3">Shipments</h3>


									<div class="dropdown">

										<button class="btn btn-white dropdown-toggle new_request_btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											New shipment
										</button>

										<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
											<a class="dropdown-item" style="line-height: 26px;" href="/dashboard/shipment/create/standard">Standard shipment (DHL) </a>
											<a class="dropdown-item" style="line-height: 26px;" href="/dashboard/shipment/create/direct">Direct shipment </a>
										</div>

									</div>

								</div>
							</div>
						</div>

						<div class="container">
							<div class="row">
								<div class="col-12 d-lg-flex justify-content-between">
									<div class="form-group pr-2 mt-5 mt-md-0 mt-lg-0" style="width: 50%">
										<label class="font-weight-500 text-dark-grey " for="keywords">Keywords</label>
										<input type="text" id="keywords" name="keywords" class="form-control border-none pl-0" v-model="filters.keywords" placeholder="Title or description...">
										<span>{{ errors.first('keywords') }}</span>
									</div>
									<!--<div class="form-group pr-2">-->
									<!--    <label for="type_filter" class="form-label font-weight-500 text-dark-grey">Type</label>-->
									<!--    <select name="type_filter" id="type_filter" v-model="filters.type_id" class="form-control border-none pl-0">-->
									<!--        <option v-for="type in filter_type_options" v-bind:value="type.id" v-html="type.title" :selected="type.id == filters.type_id"></option>-->
									<!--    </select>-->
									<!--</div>-->
									<div class="form-group pr-2">
										<label for="stock_filter" class="form-label font-weight-500 text-dark-grey">Status</label>
										<select name="stock_filter" id="stock_filter" v-model="filters.status_id" class="form-control border-none pl-0">
											<option v-for="status in filter_status_options" v-bind:value="status.id" v-html="status.title" :selected="status.id == filters.status_id"></option>
										</select>
									</div>
								</div>
							</div>
						</div>

						<div class="container" name="items table">
							<div class="row">
								<div class="col-12">
									<ul class="list-group">
										<li class="list-group-item cursor-default d-none d-md-none d-lg-block">
											<div class="container">
												<div class="row">
													<div :class="col()">
														<span class="font-weight-500 text-dark-grey">Title</span>
													</div>
													<div class="col-1">
														<span class="font-weight-500 text-dark-grey">Units</span>
													</div>
													<div class="col-2">
														<span class="font-weight-500 text-dark-grey">Cost</span>
													</div>
													<div class="col-1">
														<span class="font-weight-500 text-dark-grey">Status</span>
													</div>
													<div class="col-2">
														<span class="font-weight-500 text-dark-grey">Created at</span>
													</div>
													<div class="col-1 pr-0 text-right" v-if="is_admin">
														<span class="font-weight-500 text-dark-grey">Delete</span>
													</div>
												</div>
											</div>
										</li>

										<li class="list-group-item cursor-default" v-for="(item, itemKey) in items" v-if="items.length">
											<div class="container">
												<div class="row pt-2 pb-2">

													<div :class="col()">
														<a :href="'/dashboard/shipment/' + item.identifier">
															<span class="font-weight-500 white-space-nowrap">{{
																	item.title || 'No title'
																}}</span> </a>
													</div>

													<div class="col-lg-1">
                                                        <span class="text-light-grey white-space-nowrap">
															{{
																!item.data.reserved_stock_items.length ? 0 : number_en(item.data.reserved_stock_items.reduce(function (a, b) {
																	return a + b['amount'];
																}, 0), 0)
															}}
                                                        </span>
													</div>

													<div class="col-lg-2">
                                                        <span class="text-light-grey white-space-nowrap">
                                                            <currency_sign :currency="item.data.currency" v-if="Boolean(item.data.cost)"></currency_sign>
															{{ number_en(item.data.cost) || 'n.a.' }}
                                                        </span>
													</div>

													<div class="col-lg-1">
                                                        <span class="font-weight-500 white-space-nowrap">
                                                            {{
																Boolean(available_status_options.find(option => option.id == item.status)) ? available_status_options.find(option => option.id == item.status).title : 'n.a.'
															}}
                                                        </span>
													</div>

													<div class="col-lg-2">
														<span class="text-light-grey white-space-nowrap">
															{{ formatTimeStamp(item.created_at) }}
														</span>
													</div>

													<div class="col-lg-1 text-right" v-if="is_admin">
														<i class="fa fa-trash btn-icon cursor-pointer text-danger" @click="deleteItem(item, itemKey)" v-if="item.status == 'pending'"></i>
														<i class="fa fa-trash btn-icon text-secondary" v-else=""></i>
													</div>

												</div>
											</div>
										</li>
									</ul>
								</div>

								<div class="col-12 text-center" v-if="uicontrol.show_load_more">
									<button @click="loadMore()" class="btn btn-sm btn-outline-secondary mt-4 btn-rounded loadmore">
										Load more...
									</button>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>

import {DateTime} from "luxon";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Swal from "sweetalert2";
import currency_sign from '@/components/sections/reusable/CurrencySign';
import {number_en} from '@/misc/number_utilities';

export default {
	name: "ShipmentDashboard",
	data: function () {
		return {

			is_admin: is_admin,

			filters: {
				type_id: null,
				status_id: null,
				limit: 15
			},

			available_type_options: [
				{
					title: 'Product',
					id: 'product',
					permission_prerequisite: null
				}
			],

			available_status_options: [
				{
					title: 'Pending',
					id: 'pending'
				},
				{
					title: 'Ready',
					id: 'ready'
				},
				{
					title: 'In Progress',
					id: 'in_progress'
				},
				{
					title: 'Completed',
					id: 'completed'
				},
				{
					title: 'Delivered',
					id: 'delivered'
				},

			],

			any_option: {
				title: 'Any',
				id: null
			},

			flatpickr_config: {
				dateFormat: "Y-m-d H:i:ss",
				altInput: true,
				altFormat: "F j, Y"
			},

			items: [],

			uicontrol: {
				show_load_more: false
			},

			number_en

		}

	},

	props: [
		'user_permissions'
	],


	computed: {

		filter_status_options() {
			let options = this.available_status_options.slice(0);
			options.unshift(this.any_option);
			return options;
		},

		filter_type_options() {
			let options = this.available_type_options.slice(0);
			options.unshift(this.any_option);
			return options;
		},

		filter_urgency_options() {
			let options = this.available_urgency_options.slice(0);
			options.unshift(this.any_option);
			return options;
		},

		params() {

			let params = Object.assign({}, this.filters)

			if (params.keywords == '')
				delete params.keywords;

			// Add offset
			params.offset = this.items.length ? this.items.length + 1 : 0;

			return params

		}


	},

	watch: {

		filters: {
			deep: true,
			handler: _.debounce(function () {
					this.items = [];
					this.get().then(response => {
						this.items = response.data.results;
						this.uicontrol.show_load_more = response.data.more_results > 0;
					});
				},
				500 // debounce time
			)
		}

	},

	components: {
		flatPickr,
		currency_sign
	},

	mounted() {

		this.get().then(response => {
			this.items = response.data.results;
			this.uicontrol.show_load_more = response.data.more_results > 0;
		});

	},

	methods: {

		col() {
			return this.is_admin ? 'col-lg-5' : 'col-lg-6';
		},

		deleteItem(item, itemKey) {


			Swal.fire({
				title: 'Delete?',
				text: "This is not reversible!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {

				if (result.isConfirmed) {

					axios.delete('/dashboard/shipment/delete/' + item.identifier).then(response => {
						this.items.splice(itemKey, 1);
					});

				}
			})


		},

		get: function () {

			return axios.get('/dashboard/shipment/get', {
				params: this.params
			});

		},

		loadMore() {
			this.get().then(response => {
				this.items = this.items.concat(response.data.results);
				this.uicontrol.show_load_more = response.data.more_results > 0;
			});
		},

		formatTimeStamp(timestamp) {
			return DateTime.fromISO(timestamp).toFormat('d LLL yyyy H:mm');
		}

	},
}
</script>

<style scoped>

</style>
