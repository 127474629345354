/**
 * Format number like 1,999.50
 * @param number
 * @returns {string}
 */
function number_en(number, decimals){

    // Empty string defaults to zero
    if(number === '')
        number = 0

    if(typeof decimals === 'undefined')
        decimals = 2;

    // If string, clean commas
    if(typeof number === 'string')
        number = number.replaceAll(',', '')

    // Convert to float
    number = parseFloat(number)

    // If convert to float failed, defaults to zero
    if(isNaN(number))
        number = 0

    // Format number
    let formatted_number = Number(number).toLocaleString('en', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    });

    return formatted_number;

}

export { number_en }