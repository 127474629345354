<template>
    <div>
        Deleted node
    </div>
</template>

<script>
export default {
    name: "Deleted",
    props: ['index','data','editable'],
    data : function(){
        return {

        }
    },
    methods : {

    },
    // Node specific properties
    readable_name: "Deleted",
    type : 'deleted',
    sample_data : {
        content : '',
        tags : []
    },
}
</script>

<style scoped>


</style>
