<template>
    <div class="inner" @click="clickTitle($event)">
        <div class="ep" action="begin"></div>
        <h5 v-text="title"></h5>
    </div>
</template>

<script>
export default {
    name: "Origin",
    props: ['data','editable', 'board_title'],
    data : function(){
        return {
			click_type: [],
			delay: 300,
			clicks: 0,
			timer: null
        }
    },

    computed : {
        title(){
            return this.board_title.substr(0, 40) + (this.board_title.length > 40 ? '...' : '')
        }
    },

    methods : {

		clickTitle(event){

			this.clicks++

			if(this.clicks === 1) {
				var self = this
				this.timer = setTimeout(function() {
					self.click_type.push(event.type);
					self.clicks = 0
				}, this.delay);
			} else{
				clearTimeout(this.timer);
				this.click_type.push('double click');

				let board_title_input = document.getElementById('board-title');

				board_title_input.focus();

				console.log(board_title_input);

				this.clicks = 0;
			}

		}

	},
    // Node specific properties
    readable_name: "Origin",
    type : 'origin',
    sample_data : {
        content : '',
        tags : []
    },
}
</script>

<style scoped>

</style>
