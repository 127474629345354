<template>
    <div class="controls">
        <ul>
            <li>
                <button class="show_tag_manager" @click="$emit('show_tag_manager', node.id)">
                    Tags
                </button>
            </li>
            <li>
                <button class="delete text-danger" @click="$emit('delete_node', node.id)">
                    Delete
                </button>
            </li>
        </ul>
    </div>
</template>




<script>
export default {
    name: "NodeControls",
    props : ['node']
}
</script>

<style scoped>

</style>
